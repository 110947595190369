import { Component, OnInit, HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../../services/data.service";
import { TokenService } from "../../services/token.service";
import { Router ,ActivatedRoute} from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { AuthService as Social } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angularx-social-login';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  public form = {
    email: null,
    password: null
  };

  currYear:any;

  public error = null;
  validationmsg: any;
  email1:any;
  pass1:any;
  constructor(
    private dataservice: DataService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private authService: Social,
    private route: ActivatedRoute
  ) {
    console.log("i am here new log");
  }



  onSubmit() {
    console.log('inide this block for login')
    this.dataservice
      .login(this.form)
      .subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
      
  }

  handleResponse(data) {
    console.log(data);
    if (data.data.access_token) {
      this.Token.handle(data.data.access_token, data.data.result);
      this.Auth.changeAuthStatus(true);
      this.router.navigateByUrl("");
    } else {
      if (data.status == false) {
        console.log("smriti23",data.message);
        if (data.message && data.message != "") {
          this.validationmsg = data.message;
        } else {
          this.validationmsg = "";
        }
        this.router.navigateByUrl("/login");
      }
    }
  }

  handleError(error) {
    this.error = error.error.error;
  }
  ngOnInit() {
  this.email1 = this.route.snapshot.queryParams['e'];
  console.log(this.email1);
  this.pass1 = this.route.snapshot.queryParams['p'];
  if(this.email1 && this.email1!="" && this.pass1 && this.pass1!=""){

  this.form.email=atob(this.email1);
  this.form.password=atob(this.pass1);
  this.dataservice
      .login(this.form)
      .subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );

  }
    this.currYear=(new Date()).getFullYear();
  
    window.scrollTo(0,0);
  }
  onBlurMethod() {
    this.validationmsg = "";
  }
  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
  //     (userData) => {
  //       console.log(' sign in data : ' , userData);
  //     }
  //   );
  // }


}
