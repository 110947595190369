import { Injectable, EventEmitter  } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { TokenService } from "./token.service";
import { Observable } from "rxjs/Rx";

const baseUrl = environment.apiUrl;

@Injectable()
export class DataService {
  private httpOptions;


  constructor(private http: HttpClient, private Token: TokenService) {

  }


  signup(data) {
    return this.http.post(`${baseUrl}/signup`, data);
  }

  login(data) {
    console.log(baseUrl)
    return this.http.post(`${baseUrl}/login`, data);
  }

  sendPasswordResetLink(data) {
    return this.http.post(`${baseUrl}/sendPasswordResetLink`, data);
  }

  changePassword(data) {
    return this.http.post(`${baseUrl}/ChangePassword`, data);
  }

  getCourses() {

    return this.http.get(`${baseUrl}/get-courses`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getProfile(data) {
    return this.http.get(`${baseUrl}/getProfile/` + data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveProfile(data) {
    return this.http.post(`${baseUrl}/saveProfile`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getData(tablename) {
    return this.http.post(`${baseUrl}/get-data`, tablename, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getCourseById(courseid) {
    return this.http.get(`${baseUrl}/get-courses/` + courseid, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getMerchantKey() {
    return this.http.get(`${baseUrl}/get-merchant-key`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  makePayment(data) {
    return this.http.post(`${baseUrl}/charge`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getPaymentHistory(userid) {
    return this.http.get(`${baseUrl}/pay-history/${userid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getCourseModule(courseid, uid) {
  console.log("000000000",courseid,uid);
    return this.http.get(`${baseUrl}/get-module/${courseid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  startCourse(data) {
    return this.http.get(`${baseUrl}/start-course/` + data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getCourseStatus(data) {
    return this.http.get(`${baseUrl}/course-status/` + data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getModuleSession(cid,uid): Observable<any> {
    return this.http.get(`${baseUrl}/get-session/${cid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

  getSessionById(id) {
    return this.http.get(`${baseUrl}/get-sessionById/${id}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  startSessionData(id, user_id) {
    return this.http.get(`${baseUrl}/startSession/${id}/${user_id}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveSessionData(data) {
    return this.http.post(`${baseUrl}/saveSessionData`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  deleteSessionData(data) {
    return this.http.post(
      `${baseUrl}/delete-sessionData`,
      data,
      {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    }
    );
  }
  getCourseProgress(cid,uid) {
    return this.http.get(`${baseUrl}/get-courseprogress/${cid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getModuleSessionCount(mid, uid) {
    return this.http.get(`${baseUrl}/get-ModuleSessionCount/${mid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveCandidateRating(data) {
    return this.http.post(`${baseUrl}/saveCandRating`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getCourseRating(courseid, uid) {
    return this.http.get(`${baseUrl}/get-courseRating/${courseid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getQuestions(sessionid,qid,uid) {
    return this.http.get(`${baseUrl}/get-question/${sessionid}/${qid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveQuestionsAns(data) {
    return this.http.post(`${baseUrl}/save-question-ans/`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getQuizResult(sessionid,uid) {
    return this.http.get(`${baseUrl}/get-quiz-result/${sessionid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getQuizAttempt(sessionid,uid) {
    return this.http.get(`${baseUrl}/get-quiz-attempt/${sessionid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getPaymentInvoice(userid) {
    return this.http.get(`${baseUrl}/pay-invoice/${userid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  // updateQuizStatus(sessionid,uid) {
  //   return this.http.get(`${baseUrl}/update-quiz-status/${sessionid}/${uid}`);
  // }
  getQuizStatus(sessionid,uid) {
    return this.http.get(`${baseUrl}/get-quiz-status/${sessionid}/${uid}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getUserData(data) {
    return this.http.get(`${baseUrl}/getUserData/` + data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveComplain(data) {
    return this.http.post(`${baseUrl}/saveComplain`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveContactUs(data) {
    return this.http.post(`${baseUrl}/saveContactUs`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  updateQuizCompleted(data) {

    return this.http.post(`${baseUrl}/updateQuizCompleted`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  changePass(data) {
    return this.http.post(`${baseUrl}/changePass`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  saveQuizAttempt(data) {
    return this.http.post(`${baseUrl}/saveQuizAttempt`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  verifyEmail(data) {
    return this.http.post(`${baseUrl}/verifyEmail`, data);
  }
  InsertOrder(data) {
    return this.http.post(`${baseUrl}/InsertOrder`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  makePaymentbyworldpay(data) {
    return this.http.post(`${baseUrl}/chargeWorldpay`, data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }
  getWorldpayToken(request: any): Observable<any>{
    let worldPayApiUrl = `https://api.worldpay.com/v1/tokens`;
    let body = JSON.stringify(request);
    return this.http.post(worldPayApiUrl, body, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"

      })
    });

}
pdfdownload(data) {
    return this.http.get(`${baseUrl}/Download-Pdf/` + data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });

}
payLinkUserData(data) {
  return this.http.get(`${baseUrl}/payLinkUserData/` + data);
}
payLinkCourseData(oid,cid) {
  return this.http.get(`${baseUrl}/payLinkCourseData/` + oid+`/`+cid);
}
makePayLinkbyworldpay(data) {
    return this.http.post(`${baseUrl}/chargePayLink`, data);
}
getCountryName(id) {
  return this.http.get(`${baseUrl}/get-country-name/` + id, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });

}
GetCompletedModules(courseid, uid) {
  return this.http.get(`${baseUrl}/get-completed-module/${courseid}/${uid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}
getWrongQues(sessionid,uid) {
  return this.http.get(`${baseUrl}/get-wrong-ques/${sessionid}/${uid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getCandidateAssessmt(data) {
  return this.http.get(`${baseUrl}/getAssessmt/` + data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

SessionCompleted(data) {
  return this.http.post(`${baseUrl}/sessionCompleted`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getFirstSession(cid,mid,uid): Observable<any> {
  return this.http.get(`${baseUrl}/get-first-session/${cid}/${mid}/${uid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

saveSessionQA(data) {
  return this.http.post(`${baseUrl}/SaveSessionQA`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getSessionQA(cid,sid,rw,rwp): Observable<any> {
  return this.http.get(`${baseUrl}/get-session-QA/${cid}/${sid}/${rw}/${rwp}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getSessionQAbyid(cid,cfid): Observable<any> {
  return this.http.get(`${baseUrl}/get-session-QAId/${cid}/${cfid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

saveSessionQAReply(data) {
  return this.http.post(`${baseUrl}/SaveSessionQAReply`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getSessionQAReply(rid,rw,rwp) {
  return this.http.get(`${baseUrl}/getSessionQAReply/${rid}/${rw}/${rwp}`,  {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

saveSessionfeedback(data) {
  return this.http.post(`${baseUrl}/SaveSessionfeedback`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getSessionfeedback(cid,sid,rw,rwp): Observable<any> {
  return this.http.get(`${baseUrl}/get-session-feedback/${cid}/${sid}/${rw}/${rwp}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

getNextSession(sid,mid,cid,sessorder,morder): Observable<any> {
  return this.http.get(`${baseUrl}/get-next-session/${sid}/${mid}/${cid}/${sessorder}/${morder}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

checkTestStatus(uid) {
  return this.http.get(`${baseUrl}/checkTestStatus/` + uid, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

downloadcertificate(data) {
  return this.http.get(`${baseUrl}/Download-Certificate/` + data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });

}

KnowledgeCompStatus(id, user_id) {
    return this.http.get(`${baseUrl}/knowdCompStatus/${id}/${user_id}`, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        accesstoken: this.Token.get()
      })
    });
  }

GetNotCompletedModules(courseid, uid) {
  return this.http.get(`${baseUrl}/get-notcompleted-module/${courseid}/${uid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}
DeletePrevQuizStatus(sessionid,uid) {
  return this.http.get(`${baseUrl}/delete-quiz-status/${sessionid}/${uid}`, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

TestReachPostCandidates(data) {
  return this.http.post(`${baseUrl}/TestReachPostCandidates`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}

SaveDataFromTestReach(data) {
  return this.http.post(`${baseUrl}/SaveTestReachData`, data, {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      accesstoken: this.Token.get()
    })
  });
}


}
