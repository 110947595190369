import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';   


@Injectable({
  providedIn: 'root'
})
export class ContactmodalService {

  invokesidebarFunction = new EventEmitter();    
  subsVar: Subscription;    
    
  activeState2 = false;
  

  constructor() { }

  onsidebarClick() {    
    this.invokesidebarFunction.emit();
       
    // this.activeState2 = !this.activeState2; 

    // if(this.activeState2 = true){
    //   return 'yellow'
    // }
    // else {
    //   return ''
    // }

    console.log('contact');
  } 

}
