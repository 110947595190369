import { Injectable, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';
import { DataService } from './data.service';

@Injectable()
export class AfterLoginService implements CanActivate {
  constructor(private dataservice: DataService,
    private Token: TokenService,
    private router: Router) { }





  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.Token.get()) {
      window.location.href = '/login';
    }
    if (this.Token.loggedIn()) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

}
