import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../../services/data.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  @HostListener('mouseenter')

  @HostListener('mouseleave')



  resetText: String = 'Sign Up';
  
  openClose :boolean = true;

  showHover :boolean = true;
  showHover1 :boolean = true;

  public form = {

    first_name: null,
    last_name: null,
    mobile_number: null,
    email: null,
    password: null,
    user_name:null

  };
  currYear:any;
  verify:any;
  disabled: Boolean = false;
  show: Boolean = false;
  public error = [];
  emailexits:any;
  usernameexits:any;
  constructor(
    private dataservice: DataService,
    private Token: TokenService,
    private router: Router,
    private Notfiy:SnotifyService,
    private Auth: AuthService
  ) { }



  @HostListener('document:click', ['$event']) clickout(event) {
    this.openClose = true;
  }



  ngOnInit() {
    window.scrollTo(0,0);
  this.currYear=(new Date()).getFullYear();
  }
  onSubmit() {
    this.resetTexts();
    this.dataservice.signup(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  handleResponse(data) {
    if (data.data.access_token) {
      if(data.data.result.data.email_verification === 0){
       this.verify= btoa("verifyemail")
        this.router.navigateByUrl('/verify-email?s='+this.verify);
      }else {
        this.Token.handle(data.data.access_token, data.data.result);
        this.Auth.changeAuthStatus(true);
        if (data.data.result.data.voucher_code){
          this.router.navigateByUrl('');
        } else {
          this.router.navigateByUrl('/courses');
        }
      }
      

    } else{
      if(data.status==false){
        //console.log(data.message);
        
        if(data.message.email && data.message.email!=""){
          this.emailexits=data.message.email;
          this.show = true;
        }else {
          this.emailexits="";
        }
        if(data.message.username && data.message.username!=""){
          this.usernameexits=data.message.username;
        }else {
          this.usernameexits="";
        }
        
      }
    }
    this.resetText = 'Sign In';
    this.disabled = false;
  }

  handleError(error) {
    console.log("error--",error);
    this.error = error.error.errors;
  }
  resetTexts() {
    this.resetText = 'Sign up...';
    this.disabled = true;
  }




// hovertext(event: Event){
// this.showHover = !this.showHover;


// }
}
