import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenService } from '../services/token.service';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-course-enrollment',
  templateUrl: './course-enrollment.component.html',
  styleUrls: ['./course-enrollment.component.css']
})
export class CourseEnrollmentComponent implements OnInit {


  token: string = null;
  courseData: any;
  value: any;
  userData: any;
  sessionKey: any;
  form: any;
  worldpayClientKey: any;
  resetText: String = 'PAY NOW';
  disabled: Boolean = false;
  tokendata: any;
  mydata: any;
  userdata:any;
  public proctoruform = {
    time_sent: null,
    student_id: null,
    user_password: null,
    last_name: null,
    first_name: null,
    address1: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    phone1: null,
    email: null,
    time_zone_id: null,
       
  };
  constructor(
    private Token: TokenService,
    private Dataservice: DataService,
    private route: ActivatedRoute,
    private router: Router, private toastr: ToastrService
    ) {
    this.worldpayClientKey = environment.worldpayClientKey;
  }

  ngOnInit() {

    if (this.Token.voucherCode()) {
      this.router.navigateByUrl('/');
    }
    this.form = JSON.parse(this.Token.getUserData());
    this.value = atob(decodeURIComponent(this.route.snapshot.queryParams['id']));
    //console.log("==-----p-",this.value);
    if (this.value === '') {
      this.router.navigateByUrl('/');
    }
    this.Dataservice.getCourseById(this.value).subscribe(
      (data: any) => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    if (!data.data[0]) {
      this.router.navigateByUrl('/');
    }
    this.courseData = data.data[0];
  }

  handleError(data) {
    console.log(data);
  }

  maketoken() {
    this.mydata = JSON.parse(this.Token.getUserData());
    //console.log("0000----",this.mydata.course_id);
      if(this.mydata.course_id===null){
        Swal.fire({
          title: '<span style="font-size:14px;">Please wait while your transaction is being processed</span>',
          type: 'warning',
          width: 400,
          padding: '3em',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false
        });
       
        
        this.tokendata = {
          "reusable": true,
          "paymentMethod": {
            "name": this.form.card_name,
            "expiryMonth": this.form.expiryMonth,
            "expiryYear": this.form.expiryYear,
            "cardNumber": this.form.card_number,
            "type": "Card",
            "cvc": this.form.cvv
          },
          "clientKey": this.worldpayClientKey
        }
    
        this.Dataservice.getWorldpayToken(this.tokendata).subscribe(
          (data: any) => {
            this.token = data.token;
    
            if (data.token) {
              //this.resetText = 'Please Wait...';
              //this.disabled = true;
              this.makepayment();
            }
    
    
          },
          error => {
            Swal.close();
            //console.log("error is--- ", error.error.message);
            this.toastr.error("", error.error.message, { timeOut: 3000 });
            
          }
        );
      }else{
        this.router.navigate(['/about-course']);
        this.toastr.error("", "You have already paid for this course!", { timeOut: 3000 });
      }


  }
  makepayment() {
    if (!this.form.accept_terms) {
      Swal.fire({
        title: '<span style="font-size:14px;">Please check terms & Condition before proceeding</span>',
        type: 'warning',
        width: 400,
        padding: '3em',
        showCancelButton: false,
        showConfirmButton: true

      });
      return false;
    }

    if (this.token) {
      this.form.token = this.token;
    }
    this.form.course = this.value;
    this.form.price = this.courseData.course_price;
    this.form.vat = (this.courseData.course_price * .2);
    
    this.Dataservice.makePaymentbyworldpay(this.form).subscribe(
      (data: any) => {
        //console.log(data);
        if (data.status === true) {

          Swal.fire({
            title: '<span style="font-size:14px;">Payment Successfull.</span>',
            type: 'success',
            width: 400,
            padding: '3em',
            showCancelButton: false,
            showConfirmButton: false


          });

          this.disabled = false;
          this.Dataservice.getProfile(this.form.id).subscribe(
            (datas: any) => {
            
              if (datas.status) {
                this.userdata=datas.data;
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(datas.data));
              }

              if (!datas.data.voucher_code) {
                this.router.navigateByUrl('/courses');
              } else {
                Swal.close();
                window.location.href = 'about-course';
            
                
              }
            }
          );
         
        } else {
          if (data.status === false && (data.data[0] && data.data[0].course_id!='')) {
            
            Swal.fire({
              title: '<span style="font-size:14px;">You have already paid for this course!</span>',
              type: 'warning',
              width: 400,
              padding: '3em',
              showCancelButton: false,
              showConfirmButton: true
            }).then(function() {
              localStorage.removeItem('userData');
              localStorage.setItem('userData', JSON.stringify(data.data[0]));
              window.location.href = "about-course";
          });
           
          }else if(data.status === false && data.data===1){
            Swal.close();
            this.toastr.error("", data.message, { timeOut: 3000 });
          }
           else {
            Swal.fire({
              title: '<span style="font-size:14px;">Payment Failed! Please try again.</span>',
              type: 'error',
              width: 400,
              padding: '3em',
              showCancelButton: false,
              showConfirmButton: true
            });
          }

        }
      },
          error => {
            Swal.close();
            //console.log("error is--- ", error.error.message);
            this.toastr.error("", error.error.message, { timeOut: 3000 });
            
          }
    );
  }
  

}
