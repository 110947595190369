import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { DataService } from '../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { CourseModulesComponent } from '../../course-modules/course-modules.component';


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
  providers:[CourseModulesComponent]
})
export class ProfileComponent implements OnInit {
 
  myDateValue: Date;
 
  public form = { userid: null, file: null, dob:null };
  id: any;
  value: any;
  table: any;
  public error = [];
  nationalityData: object;
  languageData: object;
  countryData: object;
  cityData: object;
  selecetdFile: File;
  imagePreview: any;
  imagedata: any;
  profiledata: any;
  public formatData: string[] = ['yyyy-MM-dd'];
  xx:any;
  disablefield:any;
  username:any;
 imageData:any;


  constructor(
    private dataservice: DataService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private Token: TokenService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private toastr: ToastrService,
    public CourseModules:CourseModulesComponent
  ) {
    this.myDateValue = new Date();
  }

  ngOnInit() {
 
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    console.log('window scr');
    const userCredentials1 = JSON.parse(this.Token.getUserData());
    this.username = userCredentials1.first_name+" "+userCredentials1.last_name;
    let userCredentials = this.Token.get();
    const payload = this.Token.payload(userCredentials);
    //console.log("====--------",payload.userdata);
    //this.id = payload.userdata.id;
    this.value = this.route.snapshot.queryParams['id'];
   
    this.getprofiledata(this.value);
    this.getnationality();
    this.getlanguage();
    this.getcountry();
    this.dataservice.getCandidateAssessmt(atob(this.value)).subscribe((data: any) => {
    
      if(data.data){
        if(data.data.testReachID && data.data.testReachID!=''){
         this.disablefield='true';
        }else {
          this.disablefield='false';
        }
       
        }
      
    });
  
  }

  onSubmit() {
    this.form.userid = this.value;
    this.form.file = this.imagePreview;
    this.form.dob=new Date(this.form.dob).toLocaleDateString("fr-CA"); 
    
    this.dataservice.saveProfile(this.form).subscribe((data: any) => {
      if (data.status) {
        this.xx=this.toastr.success('', 'Profile Updated Successfully', { timeOut: 3000 });
        if(this.xx.toastId===1){
          localStorage.removeItem('userData');
          localStorage.setItem('userData', JSON.stringify(this.form));
          window.location.href='editprofile?id='+this.form.userid;
        }
      }
      else{
        this.toastr.error('', 'Profile Updated Error', { timeOut: 3000 });
      }
    });
  }
  getprofiledata(value) {
    this.dataservice
      .getProfile(atob(value))
      .subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
  }
  getnationality() {
    this.dataservice.getData({ table: "nationality" }).subscribe(
      data => {
        this.nationalityData = data;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }

  getlanguage() {
    this.dataservice.getData({ table: "language" }).subscribe(
      data => {
        this.languageData = data;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }

  getcountry() {
    this.dataservice.getData({ table: "country" }).subscribe(
      data => {
        this.countryData = data;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }

  handleResponse(res) {
    this.form = res.data;
    this.profiledata = res.data;
    this.handleProfileResponse(res.data);
    if(res.data.upload_file){
      this.imagedata = environment.imageUrl + res.data.upload_file;
    }
    
  }
  handleError(error) {
    console.log("error--", error);
    this.error = error.error.errors;
  }
  onFileUpload(event) {
    this.selecetdFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result;
      //console.log(reader.result);
        };
    reader.readAsDataURL(this.selecetdFile);
  }
  handleProfileResponse(data) {
    this.Token.removeUserData();
    this.Token.setUserData(data);
  }

  getinital(name){
  this.imageData=this.CourseModules.getInitials(name,1);
  return this.imageData;
  }


}