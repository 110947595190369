import { Component, OnInit, Inject } from '@angular/core';
import { DataService } from '../services/data.service';
import { TokenService } from '../services/token.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from "@angular/material";
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-test-calendar',
  templateUrl: './test-calendar.component.html',
  styleUrls: ['./test-calendar.component.css']
})
export class TestCalendarComponent implements OnInit {
  public selectedDate: Date = new Date();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  
  public minDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
  cal_date:any;
  public isViewable: boolean;
  public isViewable1: boolean;
  public isViewable2: boolean;
  textval="Book Your Test";
  userdata:any;
  userdetail: any;
  assessment_id:any;
  startdatetime:any;
  public formdata = {
   
    testReachID: null,
    user_id: null

  };
  public form11 = {
    time_sent:null
  }
  user_id: any;
  windowID:any;
  public testReachData = {
   
    user_id:null,
    firstname:null, 
    lastname:null, 
    organization:null,
    email: null,
    mobile_number: null,
    exam_id:null,
    exam_id_status: null,
    role: null,
    send_email: null

  };
  constructor( @Inject(DOCUMENT) private _document: Document,public dialogref: MatDialogRef<TestCalendarComponent>,private toastr: ToastrService, private Token: TokenService,private Dataservice: DataService) { this.windowID = environment.windowID;}

  ngOnInit() {
    
    this.userdetail = JSON.parse(this.Token.getUserData());
    
    this.isViewable=true;
     this.isViewable1=false;
     this.isViewable2=false;
    this.Dataservice.getProfile(this.userdetail.id).subscribe(
      (data: any) => {
       if(data.data){
         
       
        
       console.log("===asssss=====",this.userdetail);
       }
      },
      error => {
        console.log("error is ", error);
      }
    );
  }
 
  onSubmit(){
    Swal.fire({
      title: 'Are you sure?',
      
      type: 'info',
     
      width: 400,
      padding: '3em',
      showCancelButton: true,
      cancelButtonText:'NO',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: '<span style="font-size:14px;">Please wait while your booking is complete.</span>',
          type: 'warning',
          width: 400,
          padding: '3em',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false
        });

        this.testReachData.user_id="OSTID_"+this.userdetail.id+"_"+new Date().getTime();
        this.testReachData.firstname=this.userdetail.first_name;
        this.testReachData.lastname=this.userdetail.last_name;
        this.testReachData.organization="ConstructionHelplineAPITestOrg";
        this.testReachData.email= this.userdetail.email;
        this.testReachData.mobile_number=this.userdetail.mobile_number;
        this.testReachData.exam_id= this.windowID;
        this.testReachData.exam_id_status= "True";
        this.testReachData.role= "Candidate";
        this.testReachData.send_email= "True";
        
        
        
        this.Dataservice.TestReachPostCandidates(this.testReachData).subscribe(
          (data: any) => {
              
             if(data.data[0] && data.data[0].success == true){
             console.log("data is--- ",data.data[0].success);
               this.formdata.user_id=this.userdetail.id;
               this.formdata.testReachID=data.data[0].candidate_id;
               //this.formdata.test_booked_date=this.cal_date;
               this.Dataservice.SaveDataFromTestReach(this.formdata).subscribe(
                 (data: any) => {
 
                   if(data.data===1){
                     this.dialogref.close();
                     Swal.close();
                     this.toastr.success("", "Thank you, Your Test has been Booked Successfully!", { timeOut: 3000 });
                     //window.location.reload();
                      //$("#mydiv").load("#mydiv > *");
                      this._document.defaultView.location.reload();
                                          
                  }
                  
                 },
                 error => {
                 
                  console.log("Something went wrong in update", error);
                 
                }
               );
    
             } else {
             
             if(data.data[0] && data.data[0].success == false){
             console.log("data isddd--- ",data.data[0].success,data.data[0].errors[0]);
             this.dialogref.close();
             Swal.close();
             this.toastr.error("", data.data[0].errors[0], { timeOut: 3000 });
             }

             }
          },
          error => {
            this.dialogref.close();
            Swal.close();
            console.log("error is--- ", error.error.Message);
            if(error.error.Message){
              this.toastr.error("", "You have already booked.", { timeOut: 3000 });
            }
            
           
          }
        );
        var d = new Date($.now()).toLocaleDateString("fr-CA");
console.log("--",d);
var dt = new Date();
var time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
console.log(time);

        this.form11.time_sent=new Date(d+" "+time).toISOString();
       
      



      }
    })

 
  }
}
