import { Component, OnInit } from '@angular/core';
import { TokenService } from '../services/token.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  public form = {
    course_id: null,
    user_id: null,
    rating: null,
    comment: null
  };
  userCredentials:any;
  shwcoment: boolean = false;
  ratingDone:any;
 
  constructor(private Token: TokenService,
    private toastr: ToastrService,
    private Dataservice: DataService,public dialogRef: MatDialogRef <ConfirmationDialogComponent>) { }

  ngOnInit() {
    this.userCredentials = JSON.parse(this.Token.getUserData());
    this.ratingDone = JSON.parse(this.Token.getRatingDone());
    
  }
  oepncmnt(){
    this.shwcoment = !this.shwcoment;
  }
  onRate($event: { newValue: number }) {
    this.form.rating = $event.newValue;
    this.form.course_id = this.userCredentials.course_id;
    this.form.user_id = this.userCredentials.id;
  }
  onSubmit() {
    
    if((this.form.rating==='' || this.form.rating===null || this.form.rating==='null') ){
      this.toastr.error('', "Please rate us!", { timeOut: 3000 });
    }else if((this.form.comment==='' || this.form.comment===null || this.form.comment==='null')){
      this.toastr.error('', "Please write the comment!", { timeOut: 3000 });
    }else if(this.form.comment.length<50){
      this.toastr.error('', "Comment must be at least 50 characters long.", { timeOut: 3000 });
    }else if(this.form.comment.length>300){
      this.toastr.error('', "Comment can't be greater than 500 characters long.", { timeOut: 3000 });
    }
    else {
      this.Dataservice.getCourseRating(
        this.userCredentials.course_id,
        this.userCredentials.id
      ).subscribe((data: any) => {
         if(data.data.length==1){
        this.toastr.error('', "Thank you, you have already shared your valuable feedback.", { timeOut: 3000 });
        this.dialogRef.close();
          }
          else {
            this.Dataservice.saveCandidateRating(this.form).subscribe(data => {
              if (data) {
                this.toastr.success("", "Thank  you for rating!", { timeOut: 3000 });
                this.shwcoment = !this.shwcoment;  this.dialogRef.close();
                this.Token.setRatingDone(1);  
                
              }
            });
          }

      });

    }
  }
  close(){
    this.dialogRef.close();
   
  }
}
