import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { CoursesComponent } from './courses/courses.component';
import { CourseEnrollmentComponent } from './course-enrollment/course-enrollment.component';
import { CourseModulesComponent } from './course-modules/course-modules.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { HelpSupportComponent } from './help-support/help-support.component';
import { CourseSessionComponent } from './course-session/course-session.component';
import { StartSessionComponent } from './course-session/start-session/start-session.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { QuizComponent } from './quiz/quiz.component';
import { QuizResultComponent } from './quiz-result/quiz-result.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { PaymentLinkComponent } from './payment-link/payment-link.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { RaiseAQueryComponent } from './raise-a-query/raise-a-query.component'
import { ContactUsComponent } from './contact-us/contact-us.component'




const appRoutes: Routes = [
  { path: 'paymentlink', component: PaymentLinkComponent, canActivate: [BeforeLoginService] },
  { path: 'login', component: LoginComponent, canActivate: [BeforeLoginService] },
  { path: 'signup', component: SignupComponent, canActivate: [BeforeLoginService] },
  { path: 'editprofile', component: ProfileComponent, canActivate: [AfterLoginService]},
  { path: 'request-password-reset', component: RequestResetComponent, canActivate: [BeforeLoginService]},
  { path: 'response-password-reset', component: ResponseResetComponent, canActivate: [BeforeLoginService]},
  { path: 'about-course', component: AboutComponent, canActivate: [AfterLoginService]},
  { path: '', component: HomeComponent, canActivate: [AfterLoginService]},
  { path: 'course-enroll', component: CourseEnrollmentComponent, canActivate: [AfterLoginService]},
  { path: 'courses', component: CoursesComponent , canActivate: [AfterLoginService]},
  { path: 'start-course/:id', component: CourseModulesComponent, canActivate: [AfterLoginService]},
  { path: 'myorders', component: PaymentHistoryComponent, canActivate: [AfterLoginService]},
  { path: 'term-condition', component: TermConditionComponent, canActivate: [AfterLoginService]},
  { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AfterLoginService]},
  { path: 'faq', component: FaqComponent, canActivate: [AfterLoginService]},
  { path: 'help-support', component: HelpSupportComponent, canActivate: [AfterLoginService]},
  { path: 'course-session', component: CourseSessionComponent, canActivate: [AfterLoginService]},
  { path: 'start-session', component: StartSessionComponent, canActivate: [AfterLoginService]},
  { path: 'quiz-details', component: QuizComponent, canActivate: [AfterLoginService]},
  { path: 'quiz-result/:id', component: QuizResultComponent, canActivate: [AfterLoginService]},
  { path: 'raise-a-query', component: RaiseAQueryComponent, canActivate: [AfterLoginService]},
  { path: 'contact-us', component: ContactUsComponent, canActivate: [AfterLoginService]},
  { path: 'verify-email', component: VerifyEmailComponent, canActivate: [BeforeLoginService]},
  { path: 'payment-link', component: PaymentLinkComponent, canActivate: [BeforeLoginService]},
  { path: 'thank-you', component: ThankyouComponent, canActivate: [BeforeLoginService]},
  { path: '**', component: NotfoundComponent},


];

@NgModule({
  imports: [
       RouterModule.forRoot( appRoutes, {onSameUrlNavigation: 'reload'},
      
      )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
