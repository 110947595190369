import { Component, OnInit } from '@angular/core';
import { TokenService } from '../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { ToastrService } from "ngx-toastr";
import { DataService } from '../services/data.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
  public form = {
    session_id: null,
    ques_id: null,
    user_ans: null,
    correct_ans: null,
    user_id: null
  };
  public fomrdata1 = {
    session_id: null,
    user_id: null,
  }
  public formdata = {
    course_id: null,
    module_id: null,
    session_id: null,
    user_id: null

  };
  intro: boolean = true;
  sessionid: any;
  sessionDetails: any;
  question: any;
  qid: number;
  singleQues: any;
  queslength: number;
  ques_seq: number;
  public isViewable: boolean;
  public isViewable1: boolean;
  correctAns: number;
  wrongAns: number;
  score: any;
  result: any;
  ans_arr: any = [];
  ans_arr1: any;
  userdetail: any;
  user_id: any;
  question_id: any;
  ossUrl:any;
   hash = {};
  constructor(private toastr: ToastrService, private router: Router, private route: ActivatedRoute, private dataservice: DataService, private Token: TokenService, ) { this.ossUrl = environment.ossUrl;}

  ngOnInit() {
    this.sessionid = atob(this.route.snapshot.queryParams['id']);
    this.userdetail = JSON.parse(this.Token.getUserData());
    //console.log(this.userdetail);
    this.user_id = this.userdetail.id;

    this.dataservice.getSessionById(this.sessionid).subscribe((data: any) => {
      if (data.status) {
        if (data.data[0]) {
          this.sessionDetails = data.data[0];
         // console.log("----", this.sessionDetails);


        }
      } else {
        console.log('cannot fetch data');
      }
    });
    this.isViewable = true;
    this.isViewable1 = false;
  }

  startquiz() {
   
    this.qid = 0;
    this.intro = false;
    this.ques_seq = 1;
    this.dataservice.DeletePrevQuizStatus(this.sessionid, this.user_id).subscribe((data: any) => {
      if (data.status) {
        console.log("-",data);
        this.dataservice.getQuestions(this.sessionid, this.qid, this.user_id).subscribe((data: any) => {
          if (data.status) {
           
            //console.log(data.data[1].length);
         
            this.question=data.data[1];
            
            this.queslength = data.data[0][0].total;
            this.singleQues = this.question[0];
            this.question_id = this.singleQues.id;
    
          } else {
            this.intro = true;
          }
        });

      }
      });
  
  }
  getques(qid, ques_id) {

    //console.log("mm-----",qid,ques_id);
    
    var user_ans = $("input[name='qid_" + ques_id + "']:checked").val();
    var correctAns = this.singleQues['correct_answer'];
   this.hash[ques_id] = user_ans;
   //console.log(this.hash);
   //console.log(this.hash[ques_id]);
    $("input[type=radio]").prop("checked", false);
     if (user_ans && user_ans != '') {

      if (this.qid < this.queslength) {
        var i = this.getIndex(this.qid, 1);
        if (this.question[i]) {
         
          if ((i)< this.queslength) {
            this.ques_seq++;
            
            this.singleQues = this.question[i];
            this.question_id = this.singleQues.id;
            if(this.ques_seq==this.queslength){
            $(".nextques").text('SUBMIT');
            }else { 
            $(".nextques").text('SAVE & NEXT');
            }

          }
        }

        this.form.session_id = this.sessionid;
        this.form.ques_id = ques_id;
        this.form.user_ans = user_ans;
        this.form.correct_ans = correctAns;
        this.form.user_id = this.user_id;


        this.dataservice.saveQuestionsAns(this.form).subscribe((data: any) => {
          if (data.status) {
            //console.log("=============..=",data);

          }
        });

      }else {
      console.log("===finish");
      }
    } else {
      this.toastr.warning('', 'Please select anyone option to proceed', { timeOut: 3000,positionClass: 'toast-top-center' });

    }
  }
  prevques(qid) {
   
    if (this.qid > -1) {
      var i = this.getPrevIndex(this.qid, -1);
      this.ques_seq--;
      this.singleQues = this.question[i];
      this.question_id = this.singleQues.id;
     
      if(this.ques_seq==this.queslength){
    $(".nextques").text('SUBMIT');
    }else {
    $(".nextques").text('SAVE & NEXT');
    }
    }
  }

  getPrevIndex(currentIndex, shift){
    var len = this.question.length;
    this.qid=currentIndex + shift;
    //console.log("--",this.qid);
    return (((currentIndex + shift) + len) % len);
  }

  getIndex(currentIndex, shift){
    var len = this.question.length;
    this.qid=currentIndex + shift;
    if(this.qid< len){
      return (((currentIndex + shift) + len) % len);
    } else {

      this.dataservice.getQuizResult(this.sessionid, this.user_id).subscribe((data: any) => {
        if (data.status) {
          this.correctAns = data.data[0][0].correctAns;
          this.wrongAns = data.data[1][0].wrongAns;
          this.score = ((this.correctAns / this.queslength) * 100).toFixed(2);
          if (this.score >= 80.00) {
            this.result = "PASS";
            this.formdata.course_id = this.sessionDetails.course_id;
            this.formdata.module_id = this.sessionDetails.id;
            this.formdata.session_id = this.sessionid;
            this.formdata.user_id = this.user_id;
            this.dataservice.updateQuizCompleted(this.formdata).subscribe((data: any) => {
              if (data.status) {
                console.log("quiz completed status updated");
                this.fomrdata1.session_id = this.sessionid;
                this.fomrdata1.user_id = this.user_id;
                this.dataservice.saveQuizAttempt(this.fomrdata1).subscribe(
                  data => {
                    if (data) {
                      this.router.navigate(['quiz-result', btoa(this.sessionid)]);

                    }

                  }
                );
              }
            });

          } else {
            this.fomrdata1.session_id = this.sessionid;
            this.fomrdata1.user_id = this.user_id;
            this.dataservice.saveQuizAttempt(this.fomrdata1).subscribe(
              data => {
                if (data) {
                  this.router.navigate(['quiz-result', btoa(this.sessionid)]);

                }

              }
            );
            this.router.navigate(['quiz-result', btoa(this.sessionid)]);
          }

        }
      });

    }
        
  }

}
