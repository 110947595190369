import { Component, OnInit, HostListener } from '@angular/core';
import { TokenService } from '../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import * as $ from 'jquery';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { DataService } from '../services/data.service';
import { MatDialog } from "@angular/material";
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.css']
  
})
export class QuizResultComponent implements OnInit {

 

  viewans:boolean = false;

  panelOpenState = false;

  wrongques:any;
  userdetail: any;
  user_id: any;
  sessionid: any;
  correctAns: number;
  wrongAns: number;
  score: any;
  result: any;
  attemptmade: any;
  queslength: any;
  pieChartLabels: any;
  pieChartData: any;
  pieChartType: any;
  url: String;
  qid: any;
  modulefirstid:any;
  public isViewable1: boolean;
  shwcoment: boolean = false;
  ossUrl:any;
  public chartColors: any[] = [
    {
      backgroundColor: ["#0facf3", "#4fdb54", "#FF7360"]
    }];
    public barChartLegend = false;
    constructor(private router: Router,
     private route: ActivatedRoute,
      private dataservice: DataService, 
      public dialog: MatDialog,
      private Token: TokenService, public viewportScroller: ViewportScroller) {  
        this.ossUrl = environment.ossUrl;
        }

      

  ngOnInit() {

    this.sessionid = atob(this.route.snapshot.paramMap.get("id"));
    this.url = window.location.href;
    this.userdetail = JSON.parse(this.Token.getUserData());
    this.user_id = this.userdetail.id;
    this.qid = 0;

        this.dataservice.getQuizResult(this.sessionid, this.user_id).subscribe((data: any) => {
          if (data.status) {
            this.correctAns = data.data[0][0].correctAns;
            this.wrongAns = data.data[1][0].wrongAns;
            this.queslength = data.data[2][0].totalques;

            this.score = ((this.correctAns / this.queslength) * 100).toFixed(2);
            
           
            if (this.score >= 80.00) {
              this.result = "PASS";
            } else {
              this.result = "FAIL";
            }
            this.isViewable1 = true;
            this.pieChartLabels = ['Questions', 'Right Answer', 'Wrong Answer'];
            this.pieChartData = [this.queslength, this.correctAns, this.wrongAns];
            this.pieChartType = 'pie';

          }
          this.dataservice.getQuizAttempt(this.sessionid, this.user_id).subscribe((data: any) => {
            if (data.status) {
              this.attemptmade = data.data[0].attempted;
            }

          });
          this.dataservice.getWrongQues(this.sessionid, this.user_id).subscribe((data: any) => {
            if (data.status) {
            
              this.wrongques=data.data;
             }

          });

        });

    
    this.GetCompletedModules();

    this.dataservice.getCourseProgress(this.userdetail.course_id, this.user_id).subscribe((data: any) => {
      if (data.status) {
     
        if (data.data[0].completed == data.data[0].totsession) {
          this.dataservice.getCourseRating(
            this.userdetail.course_id,
            this.userdetail.id
          ).subscribe((data: any) => {
     
            if (data.data.length==0) {
           
                this.MsgAfterSessionCompleted();
            
                            
            }
          });
         

        } 
      }
    });

  }
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
  GetCompletedModules() {
     this.dataservice.GetNotCompletedModules(this.userdetail.course_id,this.user_id).subscribe(
      (data: any) => {
       //console.log("===2===",data.data[0].id);
        this.modulefirstid=data.data[0].id;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }

  viewAnsfun(){
  
  this.viewans = !this.viewans
  window.scrollTo(0,80)
    
}

MsgAfterSessionCompleted(){
  setTimeout(() => this.dialog.open(ConfirmationDialogComponent), 5000);
 
  
}

}