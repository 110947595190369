import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  openClose: boolean = true;
  constructor() { }


  @HostListener('document:click', ['$event']) clickout(event) {
    this.openClose = true;
  }


  ngOnInit() {
  }

  slidelogin(event: Event){
    this.openClose = !this.openClose;
    event.stopPropagation();
  }
  onClick() {
    if (window.innerWidth <= 480 ) {
      this.openClose = true;
    }
  }

}
