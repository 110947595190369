import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../services/data.service";
import { TokenService } from "../services/token.service";
import { ToastrService } from "ngx-toastr";
import { MatDialogRef } from "@angular/material";


@Component({
  selector: 'app-feedbackpopup',
  templateUrl: './feedbackpopup.component.html',
  styleUrls: ['./feedbackpopup.component.css']
})
export class FeedbackpopupComponent implements OnInit {
  shwcoment: boolean = false;
  showicon: boolean = true;
  check: any;
  userCredentials: any;
  showicon1: boolean = false;
  complete_session: any;
  tot_session: any;
  courseprogess = "0";
  chartReady: boolean= false;
  progress:any;
  resRating = 0;
  course_start:any;
    id: any;


  public pieChartData: number[];

  public form = {
    course_id: null,
    user_id: null,
    rating: null,
    comment: null
  };

  constructor(
    private Dataservice: DataService,
    private toastr: ToastrService,
    private Token: TokenService,
    private router: Router,
    public dialogRef: MatDialogRef <FeedbackpopupComponent> 
  ) {
    
  }

  ngOnInit() {
    this.userCredentials = JSON.parse(this.Token.getUserData());
    this.course_start = JSON.parse(this.Token.getCourseStart());
    if(this.course_start){
      this.course_start=this.course_start;
    }else {
      this.course_start=this.userCredentials.course_started;
    }
    //console.log("=========--------------",this.course_start);
    if (!this.userCredentials.voucher_code) {
      this.router.navigateByUrl("/courses");
    }
    this.userCredentials.created_at = this.convertDate(
      this.userCredentials.created_at
    );
    this.userCredentials.updated_at = this.convertDate(
      this.userCredentials.updated_at
    );
    
    this.userCredentials.course_enroll_date = this.convertDate(
      this.userCredentials.course_enroll_date
    );
    this.id = this.userCredentials.id;

    if(this.userCredentials.course_id!=''){
      this.Dataservice.getCourseProgress(this.userCredentials.course_id, this.id).subscribe((data: any) => {
        if (data.status) {
         // console.log(data.data);
          this.complete_session = data.data[0].completed;
          this.tot_session = data.data[0].totsession;
          if (data.data[0].completed == data.data[0].totsession) {
            this.showicon = true;
            this.showicon1 = false;
  
          } else {
            this.check = "Complete all session to give your feedback";
            this.showicon = false;
            this.showicon1 = true;
  
          }
          this.courseprogess = (
            (parseFloat(data.data[0].completed) /
              parseFloat(data.data[0].totsession)) *
            100
          ).toFixed(2);

          this.pieChartData = [ parseFloat(this.courseprogess), (100 - parseFloat(this.courseprogess))];
          this.chartReady = true;
          this.progress=this.pieChartData[0];
          $('.progress-bar').css('width',  this.progress + '%');
          //console.log("-",this.pieChartData[0])
        }
      });
    }
  
   
    }
  

    convertDate(date) {
      if (date) {
        const aux = date.split(/[- :]/);
        const created = new Date(
          aux[0],
          aux[1] - 1,
          aux[2],
          aux[3],
          aux[4],
          aux[5]
        );
        return created;
      } else {
        return null;
      }
  
    }
    
  onRate($event: { newValue: number }) {
    this.form.rating = $event.newValue;
    this.form.course_id = this.userCredentials.course_id;
    this.form.user_id = this.userCredentials.id;
  }

   
  onSubmit() {
   
    if((this.form.rating==='' || this.form.rating===null || this.form.rating==='null') ){
      this.toastr.error('', "Please rate us!", { timeOut: 3000 });
    }else if((this.form.comment==='' || this.form.comment===null || this.form.comment==='null')){
      this.toastr.error('', "Please write the comment!", { timeOut: 3000 });
    }
    else {
      
      this.Dataservice.saveCandidateRating(this.form).subscribe(data => {
        if (data) {
          this.toastr.success("", "Thank  you for rating!", { timeOut: 3000 });
          this.shwcoment = !this.shwcoment;
          this.Dataservice.getCourseRating(
            this.userCredentials.course_id,
            this.userCredentials.id
          ).subscribe((data: any) => {
      
            if (data.data[0] && data.data[0].rating_status) {
              this.resRating = data.data[0].rating;
                            
            }
          });
        }
      });
    }
    this.dialogRef.close();
  }


close(){
  this.dialogRef.close();
}
}