import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit {
  public form = {
    email: null
  };
  resetText: String = 'Reset Password';
  emailexits: any;
  disabled: Boolean = false;
  show: Boolean = true;

  currYear:any;




  constructor(
    private dataservice: DataService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currYear=(new Date()).getFullYear();
    // var elmnt = document.getElementById("loginlanding");
    // elmnt.scrollIntoView();
  }


  onSubmit() {
    this.resetTexts();
    this.dataservice.sendPasswordResetLink(this.form).subscribe(
      data => this.handleResponse(data),
      error => {
       
        this.toastr.error('', error.error.error, {
          timeOut: 3000
        });
        this.disabled = false;
      }
    );
  }

  handleResponse(res) {
      if (res.status) {
      this.emailexits = '';
      
      this.toastr.success('', res.message, { timeOut: 3000 });
      this.form.email = null;
      this.show = false;
    } else {
     this.emailexits = res.message;
    }
    this.resetText = 'Reset Password';
    this.disabled = false;

  }

  resetTexts() {
    this.resetText = 'Sending mail...';
    this.disabled = true;
  }
  
   
  }


