import { Component, OnInit } from '@angular/core';

import { DataService } from '../services/data.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {
 


  resetText: String = 'Submit';
  public form = {

    first_name: null,
    last_name: null,
    mobile_number: null,
    email: null,
    user_message:null,
    user_id:null
  };
  disabled: Boolean = false;
  showmsg : any;
  msg: Boolean = true;
  public error = [];
  userdetail:any;
  userid:any;
  profiledata:any;
  status:any;
  constructor(private dataservice: DataService,
    private Token: TokenService,
    private router: Router,
    private Notfiy:SnotifyService,private toastr: ToastrService,
    public dialogRef: MatDialogRef <ComplaintComponent>) { }

  ngOnInit() {
  this.showmsg='';
    this.userdetail = JSON.parse(this.Token.getUserData());
    //console.log(this.userdetail);
    this.userid = this.userdetail.id;
    this.getuserdata(this.userid);

  }
  onSubmit() {

  if(this.form.user_message.length > 500){
  this.showmsg="Message can't be greater than 500 characters long";
 

  }else {
  this.form.user_id=this.userid;

    this.dataservice.saveComplain(this.form).subscribe(data => {
      if (data) {
      this.status=  this.toastr.success('', 'Thank for your query !', { timeOut: 3000 });
      this.resetTexts();
      this.msging();
      this.dialogRef.close();
      }
    });
  }

    

  }
  getuserdata(value) {
    this.dataservice
      .getUserData(value)
      .subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      );
  }

  handleResponse(res) {
    this.form = res.data;
    this.profiledata = res.data;
    this.resetText = 'Submit';
    this.disabled = false;
  }

  handleError(error) {
    console.log("error--",error);
    this.error = error.error.errors;
  }
  resetTexts() {
    this.resetText = 'Sending...';
    this.disabled = true;
    this.form.user_message='';
    this.msg=false;
  }
 msging(){
    this.resetText = 'Submit';
       this.disabled = false;
      this.msg=false;
  }
  close(){
    this.dialogRef.close();
    
  }
}
