import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import {  SnotifyService,SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  resetToken:any;
  email_verification:any;
  userdata:any;
  show:boolean;
  show1:boolean=false;
  openClose :boolean = true;

  public form = {
    email : null,
    email_verification:null,
    first_name:null
   
  }
  verifymail:any;
  currYear:any;



   constructor( private route:ActivatedRoute,
    private dataservice: DataService,
    private router:Router,
    private Notify:SnotifyService) {
      route.queryParams.subscribe(params => {
        this.resetToken = params['s'];
        this.userdata=JSON.parse(atob(this.resetToken));
      });
     }




     @HostListener('document:click', ['$event']) clickout(event) {
      this.openClose = true;
    }
  

  ngOnInit() {
    window.scrollTo(0,0);
    this.currYear=(new Date()).getFullYear();
   
    if(atob(this.resetToken)=='verifyemail'){
      this.show=false;
      this.show1=false;
    
    }else {
    
      this.show=true;
       this.show1=false;
      this.form.first_name=this.userdata.first_name;
      this.form.email=this.userdata.email;
      this.form.email_verification=1;
      this.dataservice.verifyEmail(this.form).subscribe((data: any) => {
      if (data) { 
   
      if(data.data && data.data===1){
    
      this.show1=true;
      this.show=false;
      
      }
      }
    });
    }
    
  }

  slidelogin(event: Event){
    this.openClose = !this.openClose;
    event.stopPropagation();
  }
  onClick() {
    if (window.innerWidth <= 480 ) {
      this.openClose = true;
    }
  }

}
