import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from '../../services/token.service';
import { environment } from '../../../environments/environment';
import * as $ from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start-session',
  templateUrl: './start-session.component.html',
  styleUrls: ['./start-session.component.css']
})
export class StartSessionComponent implements OnInit {
  public form = {
    course_id: null,
    module_id: null,
    session_id: null,
    session_sequence: null,
    user_id: null,
    check: null,
    index_id: null
  };

  sessionid: any;
  datalist: object;
  session_name: any;
  session_desc: any;
  public isViewable: boolean;
  public isViewable1: boolean;
  singledata: any;
  dataid: Number;
  datalength: any;
  userdetail: any;
  moduleid: number;
  session_seq: any;
  user_id: number;
  ans_status:any;
 ossUrl:any;


  constructor(private dataservice: DataService,
    private route: ActivatedRoute,
    private Token: TokenService,
    private sanitizer: DomSanitizer) { this.ossUrl = environment.ossUrl; }

  ngOnInit() {

    this.userdetail = JSON.parse(this.Token.getUserData());
    //console.log(this.userdetail);
    this.user_id = this.userdetail.id;
    this.sessionid = atob(this.route.snapshot.queryParams['id']);
    this.StartSessionData(this.sessionid, this.user_id);
    this.isViewable = true;
    this.isViewable1 = false;
  }
  StartSessionData(id, user_id) {
    this.dataservice.startSessionData(id, user_id).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)

    );
  }
  handleResponse(res) {
    this.datalist = res.data.data;
    //console.log("===",this.datalist);
    this.datalength = res.data.data.length;
    this.session_name = res.data.data[0].session_name;
    this.session_desc = res.data.data[0].session_description;
    this.moduleid = res.data.data[0].module_id;
    this.session_seq = res.data.data[0].index_id;
    if (this.session_seq && this.session_seq != "") {
      this.dataid = this.session_seq;
    } else {
      this.dataid = 0;
    }
  }
  handleError(error) {

  }
  convertHtml(sessiontext) {
    return this.sanitizer.bypassSecurityTrustHtml(sessiontext);
  }
  public toggless(id, type) {
    $("input[type=radio]").prop("checked", false);
    $(".opt").removeClass("wrong-answere");
    $(".opt").removeClass("correct-answere");
    if (id != 0 && type === 'continue') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Resume!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false
      }).then((result) => {
        //$("input[type=radio]").prop("checked", false);
        if (result.value) {
          this.singledata = this.datalist[id];
          $('.back').prop('disabled', false);
          if ((this.datalength - 1) == id) {
            $('.MS-right').text('Finish');
          } else {
            $('.MS-right').text('Next').prop('disabled', false);
            }
          this.dataid = id;

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.singledata = this.datalist[0];
          this.dataid = 0;
          this.form.session_sequence = this.singledata.id;
          this.form.module_id = this.moduleid;
          this.form.session_id = this.sessionid;
          this.form.session_sequence = this.singledata.id;
          this.form.check = 1;
          this.dataservice.saveSessionData(this.form).subscribe(
            data => {
              if (data) {
                console.log("ddd", data);
              }
            }
          );
        }
      })
    } else {

      this.singledata = this.datalist[id];
      this.dataid = id;
    }
    this.isViewable = false;
    this.isViewable1 = false;

//console.log("this.datalength==",(this.datalength-1),id);
    if ((this.datalength - 1) == id) {
      $('.MS-right').text('Finish');

    } else {
      $('.MS-right').text('Next').prop('disabled', false);

    }
    //console.log("smmmmmm",this.singledata.id);
    //console.log("=====------------------",id);
    if (id === 0) {
     $(".back").prop('disabled', true);
     } else {
      //console.log("------------------");
      $('.back').prop('disabled', false);
    }
    if (id > (this.datalength - 1)) {
      this.isViewable1 = true;
    } else {
      this.isViewable1 = false;
    }

    if (type === 'continue') {
      this.form.course_id = this.userdetail.course_id;
      this.form.module_id = this.moduleid;
      this.form.session_id = this.sessionid;
      if (this.singledata) {
        this.form.session_sequence = this.singledata.id;
      }

      this.form.user_id = this.userdetail.id;
      this.form.check = 0;
      this.form.index_id = id;
      this.dataservice.saveSessionData(this.form).subscribe(
        data => {
          if (data) {
            console.log("ddd", data);
          }
        }
      );
    }
    if (type === 'next') {
      //console.log("===2===",this.singledata);
      this.form.course_id = this.userdetail.course_id;
      this.form.module_id = this.moduleid;
      this.form.session_id = this.sessionid;
      if (this.singledata) {
        this.form.session_sequence = this.singledata.id;
      }
      this.form.user_id = this.userdetail.id;
      this.form.check = 1;
      this.form.index_id = id;
      this.dataservice.saveSessionData(this.form).subscribe(
        data => {
          if (data) {
            if (id > (this.datalength - 1)) {
              this.dataservice.deleteSessionData(this.form).subscribe(
                (data: any) => {
                  if (data) {
                    console.log(data);
                  }
                }
              );
            }
          }
        }
      );
    }
  }
  closewindow() {
    window.opener.location.href='start-course';
    window.close();
   
    }
  getansw(val){
    $(".opt").removeClass("wrong-answere");
    $(".opt").removeClass("correct-answere");
    if (this.singledata.session_type && this.singledata.session_type==4) {
      if(this.singledata.correct_answer==val){
        this.ans_status=1;
        $("#answ"+val).addClass("correct-answere");
      }else {
        this.ans_status=0;
        $("#answ"+val).addClass("wrong-answere");

      }
    }
  }

}
