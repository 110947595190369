import { Component, OnInit, AfterViewInit, 
  ChangeDetectorRef, 
  HostListener} from '@angular/core';
import { AuthService } from "./services/auth.service";
import { Router } from '@angular/router';
import { TokenService } from './services/token.service';
import * as $ from 'jquery';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';


import { LiveChatWidgetApiModel } from '@livechat/angular-widget';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  hidesidebar: Boolean = false;
  isConnected = true;
  reason = '';

  openClose :boolean = false;

  title = 'app';
  public loggedIn: boolean;
  show: Boolean;
  currUrl: String;
  index: any;
  


  liveChatApi: LiveChatWidgetApiModel




  constructor(
    private Auth: AuthService,
    private router: Router,
    private Token: TokenService,
    private connectionService: ConnectionService,
    private toastr: ToastrService,



  ) {
    this.show = true;
    JSON.parse(this.Token.getUserData())

  }



  ngOnInit() {
   
  
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        console.log(this.isConnected);
        this.toastr.success("", "You are online again", { timeOut: 3000 });
      } else {
        this.toastr.error("", "You are offline again");
      }
    });
    var completeURL = window.location.pathname;
    if(completeURL == "/start-session" || completeURL == '/course-session') {
        this.hidesidebar = !this.hidesidebar;
    }
    console.log(completeURL);
    if(completeURL == "/verify-email" && localStorage.hasOwnProperty("userData")){
     localStorage.removeItem('userData');
     localStorage.clear();
      window.location.href='/login';
    }
   
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);
       
  
  }

  ngAfterViewInit() {
    this.currUrl = window.location.href;
    this.index = this.currUrl.lastIndexOf('/');
    this.currUrl = this.currUrl.substring( this.index + 1 );
    this.currUrl = this.currUrl.split('?')[0];
    if (this.currUrl === 'course-session' || this.currUrl === 'start-session') {
      this.show = false;
    }

  }

  onChatLoaded(api: LiveChatWidgetApiModel) {
    this.liveChatApi = api;
  }
}
