import { Component, OnInit, AfterViewInit,ViewChild, ElementRef,Input} from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "../services/token.service";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../services/data.service";
import Swal from "sweetalert2";
import * as $ from 'jquery';
import { StarRatingComponent } from "ng-starrating";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material";
import { TestCalendarComponent } from '../test-calendar/test-calendar.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: "app-course-modules",
  templateUrl: "./course-modules.component.html",
  styleUrls: ["./course-modules.component.css"]
})
export class CourseModulesComponent implements OnInit, AfterViewInit {


  shwTab: boolean =false;
  lockimg='assets/images/locked.png';
  lockclass='lock_img';
  lockclasscerf='lock_img';
  lockimgcertificate='assets/images/locked.png';
  quizurl:any;
  inner_comt: boolean = false;
  shownextbtn:boolean=true;
  showfinishbtn:boolean=false;
  ask_ques: boolean = false;
  showopen:boolean=false;
  shwcoment: boolean = false;
  isquiz_sec: boolean =false;
   i=0;
   j:any;
  cmntbtn = " ";
  cmntbtnclose = " ";
  public form = {
    course_id: null,
    user_id: null,
    rating: null,
    comment: null
  };
  public form1 = {
    course_id: null,
    module_id: null,
    session_id: null,
    user_id: null
       
  };
  public qaForm = {

    title: null,
    course_id:null,
    session_id: null,
    user_feedback:null,
    user_id:null
  };
  public fbkform = {

    ftitle: null,
    course_id:null,
    session_id: null,
    feedbacks:null,
    user_id:null
  };
   
  public replyform = {
    ques_ansId:null,
    user_id:null,
    qareply:null
  }
  avatar: any;
  userdetail: any;
  moduleList: any;
  show: Boolean = false;
  session_seq: any;
  datalength: number;
  user_id: number;
  sessionList: any;
  courseprogess = "0";
  sessionname = "Mouse hover to get module info";
  showhcheck: Boolean = false;
  moduleid: any;
  resRating = 0;
  ratingStatus: any;
  rating_comment: any;
  tot_session: any;
  complete_session: any;
  showStartupLink: Boolean;
  showResultStatus: Boolean;
  booktest: Boolean ;
  StartupLink:any;
  booked_date:any;
  contentdata:any;
  singledata:any;
  ans_status:any;
  splitted:any;
  mid:any;
  sessid:any;
  QAlist:any;
  QAlistbyid:any;
  replylist:any;
  row = 0;
  rowperpage = 3;
  rowReply = 0;
  rowperpageReply = 3;
  quesansid:any;
  replydata:any;
  profilepic:any;
  imageUrl: any;
  ossUrl:any;
  feedbacklist:any;
  slength:any;
  datalnth:any;
  knowledgeName:any;
  proctoPass:any;
  useremail :any;
  showmsg : any;
  showmsg1 : any;
  showmsg2 : any;
  testReachUrl: any;
  resultclass:any;
  
  @Input() text: string;
  @Input() limit: number = 150;
  truncating = true;

  loadingButtonText='download';
  quiz_sid:any;
  Resultval:any;



  @ViewChild('target',{ static: false }) target: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private Token: TokenService,
    private Dataservice: DataService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer
  
  ) {
    // console.log(this.ossUrl)
    this.imageUrl = environment.imageUrl; this.ossUrl = environment.ossUrl; this.testReachUrl = environment.testReachUrl;
  
  }


  ngOnInit() {

  this.showmsg='';
  this.showmsg1='';
  this.showmsg2='';
    if (window.innerWidth <= 992 ) {
      this.shwTab = true;
    }
    this.splitted = this.router.url.split("/", 3);
    this.mid= this.splitted[2];

    this.booktest=true;
    this.userdetail = JSON.parse(this.Token.getUserData());
    this.user_id = this.userdetail.id;
    if (this.userdetail.course_id === "") {
      this.router.navigateByUrl("/");
     
    }
    this.Dataservice.getCourseModule(
      this.userdetail.course_id,
      this.user_id
    ).subscribe((data: any) => {
      if (data.status) {
        this.moduleList = data.data;
        this.mid=this.moduleList[0]['module_data']['id'];
      } else {
        this.router.navigateByUrl("/about-course");
      }
    });
    this.Dataservice.getModuleSession(this.userdetail.course_id, this.user_id).subscribe((data: any) => {
      if (data.status) {
        this.sessionList = data.data;
       
      } else {
        this.router.navigateByUrl("/about-course");
      }
    });
   
    this.Dataservice.getFirstSession(this.userdetail.course_id,this.mid,this.user_id).subscribe((data: any) => {
      if (data.status) {
        this.singledata=data.data[0];
        console.log(this.singledata , "singledatasingledatasingledata")
        this.knowledgeName=this.singledata.session_name;
        if(this.singledata.session_type ===3 || this.singledata.session_type ===4)
        {
        this.isquiz_sec=false;
       
         }
        else { this.isquiz_sec=true;  
          
          this.quiz_sid=btoa(this.singledata.session_id); }
      this.Dataservice.startSessionData(this.singledata.session_id,this.user_id).subscribe(
    (data: any) => {
      
      this.slength=data.data.data.length;
         
         if(this.slength==1){
          this.showfinishbtn=true;
          this.shownextbtn=false;
         }
         this.Dataservice.KnowledgeCompStatus(this.singledata.session_id,this.user_id).subscribe(
          (data: any) => {
            if(data.data.data[0] && data.data.data[0].session_status==1){
            this.quizurl=data.data.data[0].session_status;
            }else {
            this.quizurl=0;
            }
          });
         });
       
      } else {
        this.router.navigateByUrl("/about-course");
      }
    });
    this.Dataservice.getCourseProgress(this.userdetail.course_id, this.user_id).subscribe((data: any) => {
      if (data.status) {
      
        this.complete_session = data.data[0].completed;
        this.tot_session = data.data[0].totsession;
        if (data.data[0].completed == data.data[0].totsession) {
        this.lockimg='assets/images/unlocked.png';
        this.lockclass='unlock_img';
          $('.ratingbtn').prop('disabled', false);
          $(".ratingbtn").attr({
            "title": "Give Your Feedback"
          });
        } else {
          $(".ratingbtn").attr({
            "title": "Complete all session to give your feedback"
          });
        }
        this.courseprogess = (
          (parseFloat(data.data[0].completed) /
            parseFloat(data.data[0].totsession)) *
          100
        ).toFixed(2);
      } else {
        this.router.navigateByUrl("/about-course");
      }
    });
    this.Dataservice.getCourseRating(
      this.userdetail.course_id,
      this.user_id
    ).subscribe((data: any) => {

      if (data.data[0] && data.data[0].rating_status) {
        this.resRating = data.data[0].rating;
        this.ratingStatus = data.data[0].rating_status;
        this.rating_comment = data.data[0].comment;
        if (this.ratingStatus) {

          $('.ratingbtn').prop('disabled', true);
          $(".ratingbtn").attr({
            "title": "Thanks, You have already given your feedback"
          });
        }
      }
    });

    this.Dataservice.getCandidateAssessmt(this.user_id).subscribe((data: any) => {
      console.log('this is data you looking at',data.data);
      if(data.data){
        if(data.data.testReachID==='' || data.data.testReachID===null || data.data.testReachID==='null' ){
          this.showStartupLink=false;
          this.booktest=true;
          this.showResultStatus=false;
        }else if(data.data.result_status==='Fail' || data.data.result_status==='Pass') {
          this.StartupLink=this.testReachUrl;
          this.booked_date=data.data.test_booked_date;
          this.showResultStatus=true;
          this.showStartupLink=false;
          this.booktest=false;
          
          if(data.data.result_status==='Pass'){
          this.lockimgcertificate='assets/images/unlocked.png';
          this.lockclasscerf='unlock_img';
          this.resultclass='passclass';
          this.Resultval='Passed';
          }else {
          this.resultclass='failclass';
          this.Resultval='Failed';
          }
        }
        else {
          this.StartupLink=this.testReachUrl;
          this.booked_date=data.data.test_booked_date;
          this.showStartupLink=true;
          this.booktest=false;
          this.showResultStatus=false;
        }
      }
      
    });

    this.sessid='all';
    this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
      if (data) {
        this.QAlist=data.data;
        this.datalnth=data.data.length;
       
      }
    
    });
    this.Dataservice.getSessionfeedback(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
      if (data) {
          this.feedbacklist=data.data;
      }
    });

    this.Dataservice.getProfile(this.user_id).subscribe(
      (data: any) => {
     
       if(data.data.upload_file){
        
        this.profilepic=data.data.upload_file;
       
        if (this.profilepic && this.profilepic !== '') {
          this.avatar = this.imageUrl + this.profilepic;
         
        } else {
          this.avatar = 'assets/images/usericon.png';
        }
       
       }
       if(data.data.proctoPass){
       this.proctoPass = data.data.proctoPass;
       }
       if(data.data.email){
       this.useremail = data.data.email;
       }
      },
      error => {
        console.log("error is ", error);
      }
    );

    

  }

  
  ngAfterViewInit() {
    
    this.Dataservice.GetNotCompletedModules(this.userdetail.course_id,this.user_id).subscribe(
      (data: any) => {
      this.singledata.session_id=data.data[0].session_id;
        if (window.innerWidth <= 992 ) {
      var container = $('.target-accor-mob'),
    scrollTo = $('#sessmob_'+data.data[0].session_id);
    if(scrollTo.length){
    container.animate({
    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
     }); }
    }else {

    var container = $('.target-accor'),
    scrollTo = $('#sess_'+data.data[0].session_id);
     if(scrollTo.length){
    container.animate({
    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
     }); }

    }
      },
      error => {
        console.log("error is ", error);
      }
    );
   
  }
 

  oepncmnt() {
  $('#myvideo').trigger('pause'); 
    if (this.complete_session == this.tot_session) {
      this.shwcoment = !this.shwcoment;
      if (this.shwcoment) this.cmntbtn = "";
      else this.cmntbtn = "";
      this.Dataservice.getCourseRating(
        this.userdetail.course_id,
        this.user_id
      ).subscribe((data: any) => {
        console.log("rating--", data.data[0].rating);
        this.resRating = data.data[0].rating;
        if (data.data[0] && data.data[0].rating_status) {
          this.ratingStatus = data.data[0].rating_status;
        }

        this.form.comment = data.data[0].comment;

      });
    } else {

      this.toastr.info('', 'Complete all session to give your feedback!', { timeOut: 3000 });
      return false;
    }

  }

  

  onRate($event: { newValue: number }) {

    this.form.rating = $event.newValue;
    this.form.course_id = this.userdetail.course_id;
    this.form.user_id = this.userdetail.id;
  }
  onSubmit() {
   
    if((this.form.rating==='' || this.form.rating===null || this.form.rating==='null') ){
      this.toastr.error('', "Please rate us!", { timeOut: 3000 });
    }else if((this.form.comment==='' || this.form.comment===null || this.form.comment==='null')){
      this.toastr.error('', "Please write the comment!", { timeOut: 3000 });
    }else if(this.form.comment.length<50){
      this.toastr.error('', "Comment must be at least 50 characters long.", { timeOut: 3000 });
    }else if(this.form.comment.length>300){
      this.toastr.error('', "Comment can't be greater than 500 characters long.", { timeOut: 3000 });
    }
    else {
      
      this.Dataservice.saveCandidateRating(this.form).subscribe(data => {
        if (data) {
          this.toastr.success("", "Thank  you for rating!", { timeOut: 3000 });
          this.shwcoment = !this.shwcoment;
          //window.location.href='/start-course';
          this.Dataservice.getCourseRating(
            this.userdetail.course_id,
            this.user_id
          ).subscribe((data: any) => {
      
            if (data.data[0] && data.data[0].rating_status) {
              this.resRating = data.data[0].rating;
              this.ratingStatus = data.data[0].rating_status;
              this.rating_comment = data.data[0].comment;
              
            }
          });
        }
      });
    }
    
  }
  test_status() {

    if (this.complete_session == this.tot_session) {
      //console.log("do something after all session is complete");
       $('#myvideo').trigger('pause'); 
      this.dialog.open(TestCalendarComponent);

    }else {
      this.toastr.info('', 'Complete all session to book your test!', { timeOut: 3000 });
      return false;
    }
  }

 
  convertHtml(sessiontext) {
    return this.sanitizer.bypassSecurityTrustHtml(sessiontext);
  }

  getansw(val){
  $('.correctAns').removeAttr("disabled");
    $(".opt_"+this.singledata.id).removeClass("wrong-answere");
    $(".opt_"+this.singledata.id).removeClass("correct-answere");
    if (this.singledata.session_type && this.singledata.session_type==4) {
      if(this.singledata.correct_answer==val){
       
        this.ans_status=1;
        $("#answ"+val+"_"+this.singledata.id).addClass("correct-answere");
        $("#check"+this.singledata.session_id).prop('checked', true);
        
      }else {
        this.ans_status=0;
        $("#answ"+val+"_"+this.singledata.id).addClass("wrong-answere");
       

      }
    }
  }
 videoEnded(sid,mid1,sess_order,m_order) {

     $("#check"+sid).prop('checked', true);
    
    this.form1.module_id = this.singledata.module_id;
    this.form1.session_id = sid;
    this.form1.course_id = this.userdetail.course_id;
    this.form1.user_id=this.user_id;
    this.Dataservice.SessionCompleted(this.form1).subscribe(
      data => {
        if (data) {
        console.log("smriti",data);
          this.Dataservice.getCourseModule(
            this.userdetail.course_id,
            this.user_id
          ).subscribe((data: any) => {
            if (data.status) {
              this.moduleList = data.data;
               console.log("smriti2",this.moduleList);
              this.mid=this.moduleList[0]['module_data']['id'];
              this.Dataservice.getNextSession(
                sid,mid1,this.userdetail.course_id,sess_order,m_order
              ).subscribe((data: any) => {
                if (data.status) {
                  if(data.data[0] && data.data[0].id!=''){
                    this.changesSession(data.data[0].id,data.data[0].is_quiz,data.data[0].session_name)
                  }else {
                  if(data.data[0].is_quiz===1){
                    window.location.reload();
                  }
                  }
                                    
                } 
              });
              
            } 
          });
       
          
        }
      }
    );
     
 }

 onSubmitQA(quesansForm) {
 
 if(this.qaForm.user_feedback.length > 500){
  this.showmsg="Message can't be greater than 500 characters long.";
  }
  else if(this.qaForm.user_feedback.length < 50){
  this.showmsg="Message must be at least 50 characters long.";
  }
  else {
  this.qaForm.course_id=this.userdetail.course_id;
  this.qaForm.session_id=this.singledata.session_id;  
  this.qaForm.user_id=this.user_id;
 
   this.Dataservice.saveSessionQA(this.qaForm).subscribe(data => {
    if (data) {
     
      this.toastr.success('', 'Question added successfully.', { timeOut: 3000 });
     
    }
    quesansForm.resetForm();
  });
  this. ask_ques = !this. ask_ques;
  this.sessid='all';
  this.row=0;
  this.rowperpage=3;
  this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
    if (data) {
        this.QAlist=data.data;
        this.datalnth=data.data.length;
    }
  });
  }

  
}
public mychange(event)
{
  if(event=='all'){
    this.sessid='all';
  }else {
    this.sessid=this.singledata.session_id;
  }
  this.row=0;
  this.rowperpage=3;
  this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
    if (data) {
        this.QAlist=data.data;
        this.datalnth=data.data.length;
       
    }
  });
}

public loadmore(){
  $('#myvideo').trigger('pause'); 
  this.row++;
  this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,(this.row*this.rowperpage),this.rowperpage).subscribe(data => {
    if (data) {
    
     this.QAlist = this.QAlist.concat(data.data);
    }
  });
}

public replyPanel(cfid){
  this.inner_comt = !this.inner_comt
  this.quesansid=cfid;
  this.Dataservice.getSessionQAbyid(this.userdetail.course_id,cfid).subscribe(data => {
    if (data) {

      this.QAlistbyid=data.data;
       
    }
  });
  this.Dataservice.getSessionQAReply(cfid,this.rowReply,this.rowperpageReply).subscribe(data => {
    if (data) {
     this.replydata=data;
     this.replylist=this.replydata.data;
      
    }
  
  });
}
public replyQuesAns(replyForm){

  if(this.replyform.qareply.length > 500){
  this.showmsg1="Reply can't be greater than 500 characters long.";
  }
  else if(this.replyform.qareply.length < 50){
  this.showmsg1="Reply must be at least 50 characters long.";
  }
  else {
  this.replyform.ques_ansId=this.quesansid;
  this.replyform.user_id=this.user_id;
  this.Dataservice.saveSessionQAReply(this.replyform).subscribe(data => {
    if (data) {
     this.rowReply=0;
     this.rowperpageReply=3;
      this.Dataservice.getSessionQAReply(this.quesansid,this.rowReply,this.rowperpageReply).subscribe(data1 => {
        if (data1) {
          this.replydata=data1;
          this.replylist=this.replydata.data;
           }
        replyForm.resetForm();
      });
     
    }
  
  });
  }
}
public seemore(){
  this.rowReply++;
  this.Dataservice.getSessionQAReply(this.quesansid,(this.rowReply*this.rowperpageReply),this.rowperpage).subscribe(data => {
    if (data) {
      this.replydata=data;
      this.replylist = this.replylist.concat(this.replydata.data);
    }
  });
}



public readmore(){

}



onSubmitfeedback(feedbackForm) {

  if(this.fbkform.feedbacks.length > 300){
  this.showmsg2="Feedback can't be greater than 300 characters long.";
  }
  else if(this.fbkform.feedbacks.length < 50){
  this.showmsg2="Feedback must be at least 50 characters long.";
  }
  else {
  this.fbkform.course_id=this.userdetail.course_id;
  this.fbkform.session_id=this.singledata.session_id;  
  this.fbkform.user_id=this.user_id;
 
   this.Dataservice.saveSessionfeedback(this.fbkform).subscribe(data => {
    if (data) {
      this.fbkform.feedbacks='';
      this.toastr.success('', 'Thank you! for your valuable feedback.', { timeOut: 3000 });
     
    }
    feedbackForm.resetForm();
  });

  this. ask_ques = !this. ask_ques;
  this.sessid='all';
  this.row=0;
  this.rowperpage=3;
  this.Dataservice.getSessionfeedback(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
    if (data) {
        this.feedbacklist=data.data;
    }
  });
  }
}
public loadmorefeedback(){
$('#myvideo').trigger('pause'); 
  this.row++;
  this.Dataservice.getSessionfeedback(this.userdetail.course_id,this.sessid,(this.row*this.rowperpage),this.rowperpage).subscribe(data => {
    if (data) {
    
     this.feedbacklist = this.feedbacklist.concat(data.data);
    }
  });
}

public nextQues(sid,i){

  $(".correctAns").attr("disabled", 'true');

  this.Dataservice.startSessionData(sid,this.user_id).subscribe(
   (data: any) => {
   
     this.singledata = data.data.data[i];
     this.i=i++;
     if((this.i+1)===data.data.data.length){
       console.log("--f----");
      this.showfinishbtn=true;
      this.shownextbtn=false;
    
  }
     
   }
 );
}
public prevQues(sid,i){
  $("input[type=radio]").prop("checked", false);
  $("#answ"+this.singledata.correct_answer+"_"+this.singledata.id).addClass("correct-answere");
     $("#check"+this.singledata.session_id).prop('checked', true);
}

public finishQues(sid){

this.form1.module_id = this.singledata.module_id;
        this.form1.session_id = this.singledata.session_id;
        this.form1.course_id = this.userdetail.course_id;
        this.form1.user_id=this.user_id;
        this.Dataservice.SessionCompleted(this.form1).subscribe(
          data => {
            if (data) {
              console.log("smriti3333",data);
              
                this.Dataservice.getCourseModule(
                  this.userdetail.course_id,
                  this.user_id
                ).subscribe((data: any) => {
                  if (data.status) {
                    this.moduleList = data.data;
                     console.log("smriti3",this.moduleList);
                    this.mid=this.moduleList[0]['module_data']['id'];
                    this.Dataservice.getNextSession(
                      this.singledata.session_id,this.singledata.module_id,this.userdetail.course_id,this.singledata.session_order,this.singledata.module_order
                    ).subscribe((data: any) => {
                      if (data.status) {
                        console.log("-000--",data.data[0]);
                        if(data.data[0] && data.data[0].id!=''){
                          this.changesSession(data.data[0].id,data.data[0].is_quiz,data.data[0].session_name)
                        }else { 
                        if(data.data[0].is_quiz===1){
                          window.location.reload();
                        }
                        }
                      } 
                    });
                  } 
                });
              
             
            }
          }
        );


}

public changesSession(sid,isquiz,sessname){


    if (window.innerWidth <= 992 ) {
      var container = $('.target-accor-mob'),
    scrollTo = $('#sessmob_'+sid);
    container.animate({
    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
    });
    }else {

    var container = $('.target-accor'),
    scrollTo = $('#sess_'+sid);
    container.animate({
    scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
});

    }


this.showfinishbtn=false;
      this.shownextbtn=true;
  this.i=0;
  if(isquiz==1){ console.log("gupta");
  this.isquiz_sec=true;
  this.quiz_sid=btoa(sid);
  this.singledata.session_id=sid;
  this.knowledgeName=sessname;
    this.Dataservice.KnowledgeCompStatus(sid,this.user_id).subscribe(
    (data: any) => {
      console.log("--0--0---",data.data.data[0]);
      if(data.data.data[0] && data.data.data[0].session_status==1){
      this.quizurl=data.data.data[0].session_status;
      }else {
      this.quizurl=0;
      }
      this.sessid='all';
      this.row=0;
      this.rowperpage=3;
      this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
        if (data) {
            this.QAlist=data.data;
            this.datalnth=data.data.length;
        }
      });
             
    }
  );


    }else {
      console.log("smriti");
  this.isquiz_sec=false;
   this.Dataservice.startSessionData(sid,this.user_id).subscribe(
    (data: any) => {
      
      this.singledata = data.data.data[0];
      this.slength=data.data.data.length;
      console.log("--0--0---",data.data.data.length,this.singledata);
      if(data.data.data.length==1){
        console.log("---999-------");
        this.showfinishbtn=true;
      this.shownextbtn=false;
      }
      this.sessid='all';
      this.row=0;
      this.rowperpage=3;
      this.Dataservice.getSessionQA(this.userdetail.course_id,this.sessid,this.row,this.rowperpage).subscribe(data => {
        if (data) {
            this.QAlist=data.data;
            this.datalnth=data.data.length;
        }
      });
             
    }
  ); }
   
}

downloadCertificate(course_id,userid){
  this.loadingButtonText = "Loading";
   $('#myvideo').trigger('pause'); 
  
  if(course_id && course_id!=''){
  this.Dataservice.checkTestStatus(userid).subscribe(
        (data1: any) => {
            if(data1.data.Exist==0){
            this.loadingButtonText = "download";  
            this.toastr.info('', data1.message, { timeOut: 3000 });
            return false;
          } else {
         if(data1.data.result_status=='Pass'){
         this.lockimgcertificate='assets/images/unlocked.png';
         this.lockclasscerf='unlock_img';
             this.Dataservice.downloadcertificate(userid).subscribe(
              (data: any) => {
              
                saveAs(data.data.url, 'certificate_'+data.data.userid+'.pdf');
                this.loadingButtonText = "download";
                
              }, error => {
                this.loadingButtonText = "download";
                console.log('error: ', error);
              }
            );

          }else {
            this.loadingButtonText = "download";
            this.toastr.info('', data1.message, { timeOut: 3000 });
             return false;
          }
        }

  })
  
  
 }
}


public askQues(){
  this.ask_ques = !this.ask_ques;

  $('#myvideo').trigger('pause'); 
 
}

public givefeedback(){
  this.ask_ques = !this.ask_ques;

  $('#myvideo').trigger('pause'); 
 
}

public stopvideo(){
  $('#myvideo').trigger('pause'); 
}


public getInitials (name,val) {
        var canvas = document.createElement('canvas');
        canvas.style.display = 'none';
        canvas.style.textAlign = 'center';
        canvas.width = 33;
        canvas.height = 33;
        document.body.appendChild(canvas);
        var context = canvas.getContext('2d');
        if(val===1){
        context.fillStyle = '#f26628';
        }else {
        context.fillStyle = '#f26628';
        }
        
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = "bold 14px Arial";
        context.fillStyle = "#FFFFFF";
        var nameArray = name.split(' ');
       
        var initials = '';
       
        for (var i = 0; i < nameArray.length; i++) {
            if (i <= 1) {
                initials = initials + nameArray[i][0];;
            }
        }
       
        if (initials.length > 1) {
            context.fillText(initials.toUpperCase(), 7, 22);
            
        }
       
        else {
            context.fillText(initials.toUpperCase(), 7, 22);
        }
        var data = canvas.toDataURL();
        document.body.removeChild(canvas);
        return data;
    }

 }
