import { Component, OnInit, OnDestroy } from "@angular/core";
import { TokenService } from "../services/token.service";
import { Router} from "@angular/router";
import { environment } from "../../environments/environment";
import { DataService } from "../services/data.service";
import { ToastrService } from "ngx-toastr";
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatDialog } from "@angular/material";
import { TestCalendarComponent } from '../test-calendar/test-calendar.component';
import { CourseModulesComponent } from '../course-modules/course-modules.component';
import * as $ from 'jquery';
import Swal from "sweetalert2";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers:[CourseModulesComponent]
})
export class HomeComponent implements OnInit {


  shwcoment: boolean = false;
  showicon: boolean = true;
  showicon1: boolean = false;
  currYear:any;
  cmntbtn = " ";
  cmntbtnclose = " ";
  chartReady: boolean= false;
  //pie chart
  public pieChartOptions: ChartOptions = {

    maintainAspectRatio: false,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return '';
        },
      },
    }
  };
  public pieChartLabels: Label[] = ['Completed', 'Pending'];
  public pieChartData: number[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgb(81, 205, 160)', 'rgb(109, 120, 173)'],
    },
  ];
  public form = {
    course_id: null,
    user_id: null,
    rating: null,
    comment: null
  };
  
  resRating = 0;
  ratingStatus: any;
  rating_comment: any;
  userCredentials: any;
  id: any;
  imageUrl: any;
  courseprogess = "0";
  tot_session: any;
  complete_session: any;
  check: any;
  countryName:any;
  course_start:any;
  showStartupLink: Boolean;
  showResultStatus: Boolean;
  booktest: Boolean ;
  StartupLink:any;
  booked_date:any;
  progress=0;
  modulefirstid:any;
  loadingButtonText='download';
  ratingDone:any;
  testReachUrl: any;
  Resultval:any;
  resultclass:any;
  constructor(
    private Dataservice: DataService,
    private Token: TokenService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public CourseModules:CourseModulesComponent
  ) {
    this.imageUrl = environment.imageUrl;
    this.testReachUrl = environment.testReachUrl;
  }

  ngOnInit() {


    this.currYear=(new Date()).getFullYear();
    //console.log("===",Math.floor(Math.random()*100));
    this.booktest=true;
    this.userCredentials = JSON.parse(this.Token.getUserData());
    this.course_start = JSON.parse(this.Token.getCourseStart());
    this.ratingDone = JSON.parse(this.Token.getRatingDone());
    if(this.ratingDone===1){
      this.GetCourseRating();

    }
    
   
    if(this.course_start){
      this.course_start=this.course_start;
    }else {
      this.course_start=this.userCredentials.course_started;
    }
    //console.log("=========--------------",this.course_start);
    if (!this.userCredentials.voucher_code) {
      this.router.navigateByUrl("/courses");
    }
    this.userCredentials.created_at = this.convertDate(
      this.userCredentials.created_at
    );
    this.userCredentials.updated_at = this.convertDate(
      this.userCredentials.updated_at
    );
    
    this.userCredentials.course_enroll_date = this.convertDate(
      this.userCredentials.course_enroll_date
    );
    this.id = this.userCredentials.id;
    if(this.userCredentials.course_id!=''){
      this.Dataservice.getCourseProgress(this.userCredentials.course_id, this.id).subscribe((data: any) => {
        if (data.status) {
          //console.log("smriti----",data.data);
          this.complete_session = data.data[0].completed;
          this.tot_session = data.data[0].totsession;
          if (data.data[0].completed == data.data[0].totsession) {
            this.showicon = true;
            this.showicon1 = false;
  
          } else {
            this.check = "Complete all session to give your feedback";
            this.showicon = false;
            this.showicon1 = true;
  
          }
          this.courseprogess = (
            (parseFloat(data.data[0].completed) /
              parseFloat(data.data[0].totsession)) *
            100
          ).toFixed(2);

          this.pieChartData = [ parseFloat(this.courseprogess), (100 - parseFloat(this.courseprogess))];
          this.chartReady = true;
          if(this.pieChartData[0]){
          this.progress=this.pieChartData[0];
          }
          
          $('.progress-bar').css('width',  this.progress + '%');
          //console.log("-",this.pieChartData[0])
        }
      });
    }
  

    this.Dataservice.getCourseRating(
      this.userCredentials.course_id,
      this.id
    ).subscribe((data: any) => {
      if (data.data[0] && data.data[0].rating_status) {
        this.resRating = data.data[0].rating;
        this.ratingStatus = data.data[0].rating_status;
        this.rating_comment = data.data[0].comment;
        if (this.ratingStatus) {
          this.showicon1 = true;
          this.showicon = false;
          this.check = 'Thanks, You have already given your feedback';

        }
      }
    });
    if(this.userCredentials.country!=''){
      this.Dataservice.getCountryName(this.userCredentials.country).subscribe(
        (data: any) => {
          if(data.data[0]){
            this.countryName=data.data[0].name;
          }
          
        
        },
        error => {
          console.log("error is ", error);
        }
      );
    }
    this.Dataservice.getCandidateAssessmt(this.id).subscribe((data: any) => {
    
      if(data.data){
        if(data.data.testReachID==='' || data.data.testReachID===null || data.data.testReachID==='null' ){
          this.showStartupLink=false;
          this.booktest=true;
          this.showResultStatus=false;
        }else if(data.data.result_status==='Fail' || data.data.result_status==='Pass') {
          this.StartupLink=this.testReachUrl;
          this.booked_date=data.data.test_booked_date;
          this.showResultStatus=true;
          this.showStartupLink=false;
          this.booktest=false;
          this.Resultval=data.data.result_status;
          if(data.data.result_status==='Pass'){
          this.resultclass='passclass';
          }else {
          this.resultclass='failclass';
          }
        }else {
          this.StartupLink=this.testReachUrl;
          this.booked_date=data.data.test_booked_date;
          this.showStartupLink=false;
          this.booktest=true;
          this.showResultStatus=false;
        }
      } 

    });
    
    this.GetCompletedModules();
  
  }

  convertDate(date) {
    if (date) {
      const aux = date.split(/[- :]/);
      const created = new Date(
        aux[0],
        aux[1] - 1,
        aux[2],
        aux[3],
        aux[4],
        aux[5]
      );
      return created;
    } else {
      return null;
    }

  }
  oepncmnt() {

    this.ratingDone = JSON.parse(this.Token.getRatingDone());
    if(this.ratingDone===1){
      this.GetCourseRating();

    }
      if (this.complete_session == this.tot_session) {
         this.shwcoment = !this.shwcoment;

        // CHANGE THE NAME OF THE BUTTON.
        if (this.shwcoment) this.cmntbtn = "";
        else this.cmntbtn = "";
        this.Dataservice.getCourseRating(
          this.userCredentials.course_id,
          this.userCredentials.id
        ).subscribe((data: any) => {
          //console.log("rating--", data.data[0].rating);
         if(data.data[0]){
          this.resRating = data.data[0].rating;
          this.form.comment = data.data[0].comment;
         }
          if (data.data[0] && data.data[0].rating_status) {
            this.ratingStatus = data.data[0].rating_status;
          }
  
          
  
        });
      } else {
  
        this.toastr.info('', 'Complete all session to give your feedback!', { timeOut: 3000 });
        return false;
      }
  
    }


  onRate($event: { newValue: number }) {
    this.form.rating = $event.newValue;
    this.form.course_id = this.userCredentials.course_id;
    this.form.user_id = this.userCredentials.id;
  }
  
  onSubmit() {
   
    if((this.form.rating==='' || this.form.rating===null || this.form.rating==='null') ){
      this.toastr.error('', "Please rate us!", { timeOut: 3000 });
    }else if((this.form.comment==='' || this.form.comment===null || this.form.comment==='null')){
      this.toastr.error('', "Please write the comment!", { timeOut: 3000 });
    }else if(this.form.comment.length<50){
      this.toastr.error('', "Comment must be at least 50 characters long.", { timeOut: 3000 });
    }else if(this.form.comment.length>300){
      this.toastr.error('', "Comment can't be greater than 500 characters long.", { timeOut: 3000 });
    }
    else {
        this.Dataservice.getCourseRating(
            this.userCredentials.course_id,
            this.userCredentials.id
          ).subscribe((data: any) => {
           
          if(data.data.length==1){
          this.toastr.error('', "Thank you, you have already shared your valuable feedback.", { timeOut: 3000 });
          this.GetCourseRating();
              }else {
                this.Dataservice.saveCandidateRating(this.form).subscribe(data => {
                  if (data) {
                    this.toastr.success("", "Thank  you for rating!", { timeOut: 3000 });
                    this.GetCourseRating();
                  }
                });

              }
           
          });
     
    }
  }
  comp_session() {
    this.toastr.info('', 'Complete all session to give your feedback!', { timeOut: 3000 });
  }
  test_status() {

    if (this.complete_session == this.tot_session) {
      //console.log("do something after all session is complete");
      //this.dialog.open(TestCalendarComponent);
      Swal.fire({
          title: '<p style="font-size:14px; line-height:1.5;">Congratulation on //completing your online course with us,<br/> To book test with us kindly give //us a call at <span style="color:#3085d6; font-size:22px;text-align: //center;display: block"> +44 0207 199 9800 </span> </p>',
          width: 500,
          padding: '3em',
          showCancelButton: false,
          confirmButtonText: '<span style="font-size:18px;font-weight:700">OK</span>',
        
        });
    }else {
      this.toastr.info('', 'Complete all session to book your test!', { timeOut: 3000 });
      return false;
    }
  }

  GetCompletedModules() {
     this.Dataservice.GetNotCompletedModules(this.userCredentials.course_id,this.id).subscribe(
      (data: any) => {
      //console.log("===2===",data);
       //console.log("===2===",data.data[0].id);
        this.modulefirstid=data.data[0].id;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }
  public downloadCertificatepdf(){
    this.CourseModules.downloadCertificate(this.userCredentials.course_id,this.id);
    }

  public  GetCourseRating(){

      this.showicon1 = true;
      this.showicon = false;
      this.ratingStatus=1;
      this.shwcoment = !this.shwcoment;
      this.Dataservice.getCourseRating(
        this.userCredentials.course_id,
        this.userCredentials.id
      ).subscribe((data: any) => {
  
        if (data.data[0] && data.data[0].rating_status) {
          this.resRating = data.data[0].rating;
                        
        }
      });

    }
}
