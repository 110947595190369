import { Component, OnInit } from '@angular/core';
import { MustMatch } from '../_helpers/must-match.validator';
import { DataService } from '../services/data.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetText: String = 'Submit';
  disabled: Boolean = false;
  model: any = {};
  userdetail:any;
  constructor(private dataservice: DataService, private Token: TokenService, 
    private router: Router,private toastr: ToastrService,
    public dialogRef: MatDialogRef <ChangePasswordComponent> ) { }

  ngOnInit() {

  }
  onSubmit() { 
    this.userdetail = JSON.parse(this.Token.getUserData());
    this.model.email=this.userdetail.email
    this.dataservice.changePass(this.model).subscribe(
      (data: any) => {
       if(data.status==true){
        this.toastr.success('', 'Password Changed Successfully.', { timeOut: 3000 });
        this.dialogRef.close();
       }else {
        this.toastr.warning('', 'Please check Old Password.', { timeOut: 3000 });
       }

      }
     
    );

   
  }
  resetTexts() {
    this.resetText = 'Updating...';
    this.disabled = true;
  }

  close(){
    this.dialogRef.close();
  }

}
