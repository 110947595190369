import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../services/data.service';
import {  SnotifyService,SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {
  public error = [];
  public form = {
    email : null,
    password : null,
    password_confirmation: null,
    resetToken : null
  }
  validatemsg: any;
  passmatch: any;
  userdata:any;

  currYear:any;


  
  constructor(
    private route:ActivatedRoute,
    private dataservice: DataService,
    private router:Router,
    private Notify:SnotifyService
  ) {
    route.queryParams.subscribe(params => {
      this.form.resetToken = params['s'];
      this.userdata=JSON.parse(atob(this.form.resetToken));
      //console.log("========",this.userdata.email);
    });
  }

  onSubmit(){
    if(this.form.password===this.form.password_confirmation){
      this.passmatch="";
      this.form.email=this.userdata.email;
      this.dataservice.changePassword(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
      )
    }else {
      this.passmatch="Password mismatch"
    }

  }
  handleResponse(data){
    if(data.status==true){
    let router = this.router;
    this.Notify.confirm('Done! Now login with new Password', {position: SnotifyPosition.rightTop,
      buttons:[
        {text: 'Okay',
        action: toster =>{
           router.navigateByUrl('/login'),
           this.Notify.remove(toster.id)
          }
      },
      ]
    })
  }else {
    this.validatemsg=data.message;
  }

  }

  handleError(error){
    this.error = error.error.errors;
  }
  ngOnInit() {
    this.currYear=(new Date()).getFullYear();
  }

}
