import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
  
  public videoId;


  videos : any[] = [
       {  "id": 1,
       "name": "session 1",
      "src": "/assets/images/session.mp4",
      'type': 'video/mp4'
      },

       {  "id": 2,
       "name": "session 2",
       "src": "/assets/images/session.mp4",
       'type': 'video/mp4'
      },

         {  "id": 3,
       "name": "session 3",
       "src": "/assets/images/session.mp4",
       'type': 'video/mp4'
      },
      {  "id": 4,
      "name": "session 4",
      "src": "/assets/images/session.mp4",
      'type': 'video/mp4'
     }

  ]

  constructor(private router:Router,
     private route:ActivatedRoute) {
  
  }

  ngOnInit() {
    let id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.videoId = id;
  }
  
  videoSelect(video){
    this.router.navigate(['/term-condition', video.id]);
   
  }


}
