import { Component, OnInit, ViewChild, Output, EventEmitter, Renderer2,ElementRef} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";




import { TokenService } from "../services/token.service";
import { DataService } from "../services/data.service";
import { AuthService } from "../services/auth.service";
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr'
import { MatDialog } from "@angular/material";
import { ComplaintComponent } from '../complaint/complaint.component';
import { ContactmodalComponent } from '../contactmodal/contactmodal.component'



@Component({
selector: "app-sidebars",
  templateUrl: "./sidebars.component.html",
  styleUrls: ["./sidebars.component.css"],
  host: {
    '(window:click)': 'onClick($event)',
  },

})
export class SidebarsComponent implements OnInit {

  openClose: Boolean = false;
  hidesidebar: Boolean = false;
  isConnected = true;
  reason = '';

  title = 'app';
  public loggedIn: boolean;
  show: Boolean;
  currUrl: String;
  index: any;
  displays: Boolean = false;
  displays1: Boolean = false;
  displays2: Boolean = false;
  userdetail: any;
  mycourse: any;
  modulelist: any;
  completed_status:any;

  id: any;

  
  @Output() public childEve = new EventEmitter();

  @ViewChild('togglemenu',{ static: false }) toggleRef: ElementRef;
 
  // @ViewChild('menutemp',{ static: false }) menuRef: ElementRef;

  // @ViewChild('menutempbold',{ static: false }) menutempboldRef: ElementRef;

  @ViewChild('menutemp2',{ static: false }) menuRef2: ElementRef;

  @ViewChild('menutempbold2',{ static: false }) menutempboldRef2: ElementRef;

  
  @ViewChild('menutemp3',{ static: false }) menuRef3: ElementRef;

  @ViewChild('menutempbold3',{ static: false }) menutempboldRef3: ElementRef;

  


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private Token: TokenService,
    private Dataservice: DataService,
    private Auth: AuthService,
    public dialog: MatDialog,
    private connectionService: ConnectionService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private elref: ElementRef,
  
  ) {
      
    this.renderer.listen('window', 'click',(e:Event)=>{

      
  });
    
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && document.getElementById(fragment) != null) {
        document.getElementById(fragment).scrollIntoView({ behavior: "smooth" });
      }
    });

  }



  

  ngOnInit() {

    // console.log(this.userdetail.course_id, 'this.userdetail.course_idthis.userdetail.course_id')


    this.router.onSameUrlNavigation = 'reload';
    this.userdetail = JSON.parse(this.Token.getUserData());
    this.id = btoa(this.userdetail.id);
    if (this.userdetail.course_id === "") {
      this.router.navigateByUrl("/");
    }
    if (this.userdetail.course_id != "" && this.userdetail.course_id!= null) {
    this.GetCourseById();
    }
    //this.GetModulesById();
    //this.GetCompletedModules();
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {

        this.toastr.success("", "You are online again", { timeOut: 3000 });
      } else {
        this.toastr.error("", "You are offline again");
      }
    });
    var completeURL = window.location.pathname;
    if(completeURL == "/start-session" || completeURL == '/course-session') {
        this.hidesidebar = !this.hidesidebar;
    }

    this.Auth.authStatus.subscribe(value => this.loggedIn = value);

  }
  
  ngAfterViewInit() {
    this.currUrl = window.location.href;
    this.index = this.currUrl.lastIndexOf('/');
    this.currUrl = this.currUrl.substring( this.index + 1 );
    this.currUrl = this.currUrl.split('?')[0];
    if (this.currUrl === 'course-session' || this.currUrl === 'start-session') {
      this.show = false;
    }

  }
  GetCourseById() { 
    this.Dataservice.getCourseById(this.userdetail.course_id).subscribe(
      (data: any) => {
        if (data.data[0]) {
          this.mycourse = data.data[0].course_name;
        }
      }
    );
  }
  GetCompletedModules() {

    this.Dataservice.GetCompletedModules(this.userdetail.course_id,this.userdetail.id).subscribe(
      data => {
   
        this.modulelist=data;
      },
      error => {
        console.log("error is ", error);
      }
    );
  }
  // GetModulesById() {
  //   this.Dataservice.getCourseModule(this.userdetail.course_id,this.id).subscribe(
  //     data => {
  //       this.modulelist = data;
  //     },
  //     error => {
  //       console.log("error is ", error);
  //     }
  //   );
  // }
  logout() {
    event.preventDefault();
    this.Token.remove();
    this.Token.removeUserData();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl("/login");
  }


  toggleClass(){
    this.openClose = !this.openClose;
   
  }



  opencomplain(){
    this.dialog.open(ComplaintComponent, { panelClass: 'custom-dialog-container' });
  
  }
  opencontact(){
    this.dialog.open(ContactmodalComponent, { panelClass: 'custom-dialog-container' });
  }

  onClick(e) {

  if(this.userdetail.course_id){

    if(    e.target !== this.toggleRef.nativeElement && 
           e.target !== this.menuRef2.nativeElement && 
           e.target !== this.menutempboldRef2.nativeElement &&
           e.target !== this.menuRef3.nativeElement && 
           e.target !== this.menutempboldRef3.nativeElement ){ 
            this.openClose= false;
           
  
  }
  } else {
 
    if(    e.target !== this.toggleRef.nativeElement  && 
           e.target !== this.menuRef2.nativeElement && 
           e.target !== this.menutempboldRef2.nativeElement &&
           e.target !== this.menuRef3.nativeElement && 
           e.target !== this.menutempboldRef3.nativeElement ){ 
            this.openClose= false;
            console.log('window click');
  
  }
  }
   }
  
   onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
}
  


}