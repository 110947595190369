import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes} from '@angular/animations';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  animations: [
    trigger('slidemenu', [
      state('close', style({width:'0px', visibility:'hidden'})),
      state('open', style({width:'30%'})),
      transition('open => close', animate('1s ease-in')),
      transition('close => open', animate('1s ease-in'))
    ]),

    trigger('wrap-body', [
      state('close', style({marginLeft:'0px'})),
      state('open', style({marginLeft:'30%'})),
      transition('open => close', animate('1s ease-in')),
      transition('close => open', animate('1s ease-in'))

    ]),

  ]
})
export class FaqComponent implements OnInit {

// openClose : string ='open';

 showFiller = false;

  constructor() { }

  ngOnInit() {
  }


// showHidemenu(): void{

//   this.openClose = (this.openClose === 'open' ) ? 'close' : 'open'; 

// }

}
