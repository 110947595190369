import { Component, OnInit, Inject, Input } from '@angular/core';
import { TokenService } from '../services/token.service';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material";
import {InvoiceComponent} from './invoice/invoice.component'
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  userdetail: any;
  paymentHistory: any;
 public showinvoice  =  'invoicemodal';
  public statusbg = true;
 fileUrl:any;
 public statusClasses = {
 "status-green" : this.statusbg,
  "status-red" : !this.statusbg
 }
 textmsg:any;
 shwinvo : boolean = false;
 loadingButtonText='download';


  constructor(private Token: TokenService,
    private dataService: DataService,
    private router: Router, 
    public dialog: MatDialog,private sanitizer: DomSanitizer ) {  }

  ngOnInit() {
    
    this.userdetail = JSON.parse(this.Token.getUserData());
    console.log("------",this.userdetail);
    if (this.userdetail.id === '') {
      this.router.navigateByUrl('/');
    }
    this.dataService.getPaymentHistory(this.userdetail.id).subscribe(
      (data: any) => {
        if (!data.data[0]) {
          this.router.navigateByUrl('/');
        }
        this.paymentHistory = data.data;
        //console.log(this.paymentHistory);
      }
    );
    if(this.userdetail.course_id && this.userdetail.course_id!=''){
      this.textmsg="Download Invoice";
    }else {
      this.textmsg="Payment Fail";
    }
  
  }

  oepninvo(){
    this.dialog.open(InvoiceComponent, { panelClass: 'custom-dialog-container' });
    
    window.scrollTo(0,0);
    console.log('scroll to 00');
};

  downloadPdf(){
   this.loadingButtonText = "Loading";
  if(this.userdetail.course_id && this.userdetail.course_id!=''){
   
    this.dataService.pdfdownload(this.userdetail.id).subscribe(
      (data: any) => {
        console.log("=======",data);
        saveAs(data.data.url, 'invoice_'+data.data.invoid+'.pdf');
        this.loadingButtonText = "download";
        //  var link = document.createElement('a');
        //           link.href = data.data.url;
        //           link.download = '';
        //           link.click();
        //           window.URL.revokeObjectURL(data.data.url);
        //           console.log("link",link);
      }, error => {
        console.log('error: ', error);
      }
    );
  } else {
   
    this.router.navigateByUrl('/courses');
  }
 


}
}