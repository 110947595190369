import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
@Injectable()
export class TokenService {
  private iss = {
    login: 'http://localhost:3000/api/login',
    signup: 'http://localhost:3000/api/signup'
  };
  userCredentials: any;
  avatar: BehaviorSubject <any>;
  constructor(private router: Router) { }

  handle(token, userData) {
    this.set(token, userData);
  }

  set(token, userData) {
    localStorage.setItem('token', token);
    localStorage.setItem('userData', JSON.stringify(userData.data));
  }
  get() {
    return localStorage.getItem('token');
  }
  setUserData(userData) {
     localStorage.setItem('userData', JSON.stringify(userData));
  }
  getUserData() {
    return localStorage.getItem('userData');
  }
  removeUserData() {
    localStorage.removeItem('userData');
  }

  remove() {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('userData.course_started');
    localStorage.removeItem('profileImage');
    localStorage.removeItem('userData.ratingDone');
  }

  isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) >= -1 ? true : false;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }

  voucherCode() {
    this.userCredentials = JSON.parse(this.getUserData());
    if (this.userCredentials.voucher_code) {
      return true;
    }
  }
  getCourseStart() {
    return localStorage.getItem('userData.course_started');
  }
  setCourseStart(data) {
    return localStorage.setItem('userData.course_started', data);
 }
 setRatingDone(data) {
  return localStorage.setItem('userData.ratingDone', data);
 }
 getRatingDone() {
  return localStorage.getItem('userData.ratingDone');
}

}
