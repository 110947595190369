import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { TokenService } from '../services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  courseData : object;
  utf8Bytes:any;
  constructor(private dataservice: DataService, private Token: TokenService, private router: Router ) {
  }

  ngOnInit() {
    if (this.Token.voucherCode()) {
      this.router.navigateByUrl('/');
    }
    this.dataservice.getCourses().subscribe(
      (data: any) => {
        this.courseData = data.data;
        console.log(this.courseData);
      }
    );
  }

 
  courseEnroll(str){
  
  this.router.navigate(['/course-enroll'], { queryParams: { id: btoa(str) } });
  
  }

}
