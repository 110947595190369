import { Component, OnInit, HostListener } from '@angular/core';
import { TokenService } from '../services/token.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from "@angular/material";
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ComplaintComponent } from '../complaint/complaint.component';
import {ContactmodalComponent} from '../contactmodal/contactmodal.component'
import { DataService } from '../services/data.service';
import { CourseModulesComponent } from '../course-modules/course-modules.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers:[CourseModulesComponent]
})
export class HeaderComponent implements OnInit {
  firstname: any;
  lastname: any;
  avatar: any;
  userCredentials: any;
  imageUrl: any;
  showflag: Boolean = false;
  id:any;
  profilepic:any;
  username:any;
  constructor(private Token: TokenService,
    private Auth: AuthService,
    private router: Router,private route: ActivatedRoute,
    public dialog: MatDialog,private dataservice: DataService,public CourseModules:CourseModulesComponent) { this.imageUrl = environment.imageUrl; }

  @HostListener('document:click', ['$event']) clickout(event) {
    this.showflag = false;
  }

  ngOnInit() {
    const userCredentials = JSON.parse(this.Token.getUserData());
    //console.log("===",userCredentials);
    this.firstname = userCredentials.first_name;
    this.username = userCredentials.first_name+" "+userCredentials.last_name;
    this.id = btoa(userCredentials.id);
      this.dataservice.getProfile(userCredentials.id).subscribe(
        (data: any) => {
         
         if(data.data.upload_file){
         
          this.profilepic=data.data.upload_file;
         
          if (this.profilepic && this.profilepic !== null) {
            this.avatar = this.imageUrl + this.profilepic;
           
          } else {
          
            this.avatar = this.CourseModules.getInitials(this.username,1);
            
          }
         
         }else {
        
            this.avatar = this.CourseModules.getInitials(this.username,1);
          
          }
        },
        error => {
          console.log("error is ", error);
        }
      );
  
     this.lastname = userCredentials.last_name;
  }
  showdropdown(event: Event) {
    this.showflag = !this.showflag;
    event.stopPropagation();
  }

  logout() {
    event.preventDefault();
    this.Token.remove();
    this.Token.removeUserData();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/login');
  }


  openpwd(){
    this.dialog.open(ChangePasswordComponent);
  }

  opencomplain(){
    this.dialog.open(ComplaintComponent);
  }
  opencontact(){
    this.dialog.open(ContactmodalComponent);
  }

}
