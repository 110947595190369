import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  
  panelOpenState = false;          
  

  public show:boolean = false;
  public buttonName:any = '';
 
  public showSec:boolean = false;
  public buttonSec:any = '';
  
  public showthird:boolean = false;
  public buttonthird:any = '';
   
  userdetail: any;
  value: any;
  courseData: any;
  courseid: any;
  id: any;
  flag: any;
  course_start:any;
  modulefirstid:any;
    constructor(private router: Router,
      private route: ActivatedRoute,
      private Token: TokenService,
      private Dataservice: DataService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.userdetail = JSON.parse(this.Token.getUserData());
    this.course_start = JSON.parse(this.Token.getCourseStart());
    if(this.course_start){
      this.course_start=this.course_start;
    }else {
      this.course_start=this.userdetail.course_started;
    }
    this.courseid = this.userdetail.course_id;
    this.id = this.userdetail.id;
    //this.startcourse(this.id);
    //console.log("nn--",this.course_start);
    if (this.userdetail.course_id === '') {
      this.router.navigateByUrl('/');
    }
    this.Dataservice.getCourseById(this.userdetail.course_id).subscribe(
      (data: any) => {
        //console.log(data);
        if (!data.data[0]) {
          this.router.navigateByUrl('/');
        }
        this.courseData = data.data[0];
        //console.log(this.courseData);
      }
    );
    this.GetCompletedModules();
  }
  public startcourse(id) {
     this.Dataservice.startCourse(id).subscribe(
      (data: any) => {
        
        if (data.status) {
        
          this.Token.setCourseStart('1');
          
        }
      }
    );

  }

  
  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)  
      this.buttonName = "";
    else
      this.buttonName = "";
  }

  toggleSec() {
    this.showSec = !this.showSec;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.showSec)  
      this.buttonSec = "";
    else
      this.buttonSec = "";
  }

  thirdtoggle() {
    this.showthird = !this.showthird;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.showthird)  
      this.buttonthird = "";
    else
      this.buttonthird = "";
  }

  GetCompletedModules() {
    this.Dataservice.GetNotCompletedModules(this.userdetail.course_id,this.id).subscribe(
     (data: any) => {
      // console.log("===2===",data.data[0].module_data.id);
       this.modulefirstid=data.data[0].id;
     },
     error => {
       console.log("error is ", error);
     }
   );
 }


}
