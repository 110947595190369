import { Directive, OnInit, Input, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from './services/data.service';


@Directive({
  selector: '[appLoadsession]'
})
export class LoadsessionDirective implements OnInit {
  @Input() set appLoadsession(value) {
    if (value) {
      this.vcRef.createEmbeddedView(this.templateRef);
    }
  }
  @Output() public myOutput = new EventEmitter();;
  constructor(private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
    private Dataservice: DataService,
    private router: Router) { }

  ngOnInit() {
    console.log('i get called');
 }

}
