// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.onlineskillstraining.co.uk/api/',
  imageUrl: 'https://api.onlineskillstraining.co.uk/',
  ossUrl: 'https://old.onlineskillstraining.co.uk/oss-crm/public/',
  worldpayClientKey: 'T_C_82c6a4f4-56f2-4b65-b0fe-974a1c2c0852',
  testReachUrl: 'https://app.testreach.com/system-requirements',
  windowID : 'OST123'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
