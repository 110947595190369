import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  
})
export class InvoiceComponent implements OnInit{
  userdetail: any;
  paymentInvoice: any;
  inv_date:any;
 
  constructor(private Token: TokenService,private dataService: DataService,
    private router: Router) { }

  ngOnInit() {
  
    this.userdetail = JSON.parse(this.Token.getUserData());
    if (this.userdetail.id === '') {
      this.router.navigateByUrl('/');
    }
    this.dataService.getPaymentInvoice(this.userdetail.id).subscribe(
      (data: any) => {
       
        if (!data.data[0]) {
          this.router.navigateByUrl('/');
        }
        this.paymentInvoice = data.data[0];
        console.log(this.paymentInvoice);
        this.inv_date=(this.paymentInvoice.created_at).replace(/,/g, '');
      }
    );
  }

 

}
