import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../services/data.service';
import {  SnotifyService } from 'ng-snotify';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.css']
})
export class PaymentLinkComponent implements OnInit {

  token: string = null;
  tokendata:any;
  resetToken:any;
  viewdetails : boolean = false;
  userdata:any;
  worldpayClientKey:any;
  public form = {
    address: null,
    postcode: null,
    city: null,
    name_card: null,
    card_number:null,
    cvv:null,
    expiryMonth:null,
    expiryYear:null,
    accept_terms:null,
    token:null,
    course:null,
    price:null,
    vat:null,
    orderid:null,
    id:null,
    first_name:null,
    last_name:null,
    email:null,
    mobile_number:null,
    invoice_id:null,
    order_status:null
  };
  user_data:any;
  course_data:any;
  resetText: String = 'Make Payment';
  disabled: Boolean = false;
  constructor(private route:ActivatedRoute,
    private dataservice: DataService,
    private router:Router,
    private toastr: ToastrService) {this.worldpayClientKey = environment.worldpayClientKey; }

  
  ngOnInit() {
    this.resetToken = this.route.snapshot.queryParams['s'];
    console.log("-",this.resetToken);
    this.userdata=JSON.parse(atob(this.resetToken));
    console.log("-----",this.userdata);
    this.dataservice.payLinkUserData(this.userdata.userid).subscribe(
      (data: any) => {
        this.user_data=data.data;
        }
    );
    this.dataservice.payLinkCourseData(this.userdata.orderid,this.userdata.courseId).subscribe(
      (data: any) => {
        this.course_data=data.data;
        console.log("====",this.course_data);
        }
    );
   
  }
  maketoken() {
    if(this.course_data.order_status=='0'){
      Swal.fire({
        title: '<span style="font-size:14px;">Please wait while your transaction is being processed</span>',
        type: 'warning',
        width: 400,
        padding: '3em',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      });
     
      this.tokendata={
        "reusable": true,
            "paymentMethod": {
            "name": this.form.name_card,
            "expiryMonth": this.form.expiryMonth,
            "expiryYear": this.form.expiryYear,
            "cardNumber": this.form.card_number,
            "type": "Card",
            "cvc": this.form.cvv
        },
        "clientKey": this.worldpayClientKey
    }
      
      this.dataservice.getWorldpayToken(this.tokendata).subscribe(
        (data: any) => {
          this.token=data.token;
          //console.log("===",this.token);
          if(data.token){
            
            this.makepayment();
          }
              
        },
        error => {
          Swal.close();
          //console.log("error is--- ", error.error.message);
          this.toastr.error("", error.error.message, { timeOut: 3000 });
          
        }
      );
    }else{
      this.router.navigate(['/login']);
      this.toastr.error("", "You have already paid for this course!", { timeOut: 3000 });
    }

    }

    makepayment() {
      if (!this.form.accept_terms) {
       Swal.fire({
        title: '<span style="font-size:14px;">Please check terms & Condition before proceeding</span>',
        type: 'warning',
        width: 400,
        padding: '3em',
         showCancelButton: false,
         showConfirmButton: true
         
       });
       return false;
     }
      
     
     if(this.token){
       this.form.token=this.token;
     }
     this.form.first_name = this.user_data.first_name;
     this.form.last_name = this.user_data.last_name;
     this.form.mobile_number = this.user_data.mobile_number;
     this.form.email = this.user_data.email;
     
     this.form.course = this.userdata.courseId;
     this.form.price = this.course_data.product_price;
     this.form.vat = (this.course_data.product_price * .2);
     this.form.orderid=this.userdata.orderid;
     this.form.id=this.userdata.userid;
     this.form.invoice_id = this.course_data.invoice_id;
     this.form.order_status = this.course_data.order_status;
       
     this.dataservice.makePayLinkbyworldpay(this.form).subscribe(
       (data: any) => {
         console.log(data);
           if (data.status) {
             //Swal.close();
             Swal.fire({
              title: '<span style="font-size:14px;">Payment Successfull.</span>',
              type: 'success',
               width: 400,
               padding: '3em',
               showCancelButton: false,
               showConfirmButton: false
                       
             });
             Swal.close();
                this.router.navigate(['/thank-you']);
             
           } else {
            if (data.status === false && data.data === 2) {
              Swal.close();
              Swal.fire({
                title: '<span style="font-size:14px;">You have already paid for this course!</span>',
                type: 'warning',
                width: 400,
                padding: '3em',
                showCancelButton: false,
                showConfirmButton: true
              }).then(function() {
                window.location.href = "login";
            });
            }else if(data.status === false && data.data === 1){
              Swal.close();
              this.toastr.error("", data.message, { timeOut: 3000 });
            }
             else {
              Swal.fire({
                title: '<span style="font-size:14px;">Payment Failed! Please try again.</span>',
                type: 'error',
                width: 400,
                padding: '3em',
                showCancelButton: false,
                showConfirmButton: true
              });
            }
           }
       },
       error => {
         Swal.close();
         console.log("error is--- ", error);
         //this.toastr.warning("", error.error.message, { timeOut: 3000 });
         
       }
     );
   }  


}
