import { Component, OnInit} from '@angular/core';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-course-session',
  templateUrl: './course-session.component.html',
  styleUrls: ['./course-session.component.css']
})
export class CourseSessionComponent implements OnInit {
  sessionData: any;
  sessionName: any;
  sessionDesc: any;
  sessionTime: any;
  sessionid: any;
  moduleName:any;
 constructor(private dataservice: DataService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.sessionid = this.route.snapshot.queryParams['id'];
    this.getSessionData(atob(this.sessionid));
    console.log('screen with',screen.height);
  }

  getSessionData(id) {

    this.dataservice.getSessionById(id).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)

  );
}
handleResponse(res) {
  
  this.sessionName = res.data[0].session_name;
  this.sessionDesc = res.data[0].session_description;
  this.sessionTime = res.data[0].session_duration;
  this.moduleName = res.data[0].module_name;

}
handleError(error) {
 console.log("error--", error);

}
}
